const initialState = {
  betReasons: [],
  rejectionBetReason: [],
  cancelltionBetReason : []
};
export const getBetReasonsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BET_REASONS":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
