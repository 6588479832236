export const GET_SENDER_RECEIVER_HISTORY_REQUEST =
  "GET_SENDER_RECEIVER_HISTORY_REQUEST";
export const GET_SENDER_HISTORY_SUCCESS = "GET_SENDER_HISTORY_SUCCESS";
export const GET_RECEIVER_HISTORY_SUCCESS = "GET_RECEIVER_HISTORY_SUCCESS";
export const GET_SENDER_RECEIVER_HISTORY_FAIL =
  "GET_SENDER_RECEIVER_HISTORY_FAIL";

export const GET_CHAT_HISTORY_REQUEST = "GET_CHAT_HISTORY_REQUEST";
export const GET_CHAT_HISTORY_SUCCESS = "GET_CHAT_HISTORY_SUCCESS";
export const GET_CHAT_HISTORY_FAIL = "GET_CHAT_HISTORY_FAIL";
