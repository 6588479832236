import { createSlice } from "@reduxjs/toolkit";
import {
  fetchGroupBetCategories,
  UpdateGBCategoryStatusBySportId,
  fetchGroupBetMarkets,
  fetchGroupBetCompetitions,
  UpdateGBCompetitionStatusByCompititionId,
  fetchGroupBetFixtures,
  UpdateGBEventStatusByEventId,
  fetchCreateUpdateMarkets,
  UpdateGroupBetMarketStatus,
  updateCategoriesFilters,
  fetchGroupBetFixturesParentDetails,
  fetchGroupBetMarketFixtureItemCount,
  getGroupBetMarketsForFixture,
  getGroupBetMarketOption,
  UpdateGBMarketOptionStatusById,
  fetchCreateUpdateOptionsforMarket,
  updateGBMarketOptionPriorities,
  updateGBMarketPriorities,
  getGroupBetBaseMarkets,
  updateJPMarketPriorities,
  updateJPMarketOptionPriorities,
  updateCompititionPriorities,
  getConsoleGaming,
  createConsoleGameBet,
  createJackPotBet,
  createUpdateRound,
  getFixturesforRound,
} from "../../../utility/thunks";
import {
  ItemLimitsInPage,
  gbsortByMarketOptions,
  GBsortByOptions,
  GBsortByFixtureOptions,
  sortByConsoleGaming,
} from "../../../commons";

export const initialFilterState = {
  searchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList: "",
};

const initialState = {
  loading: true,

  catogoriesList: {
    selectedItemIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    categoriesListSelectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    sortBy: GBsortByOptions[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
  compititionList: {
    selectedItemIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    categoriesListSelectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    sortBy: GBsortByOptions[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
  fixturesList: {
    selectedItemIds: [],
    updatingStatusIds: [],
    selectedCountry: "",
    parents: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    errorNo: 0,
    errorMessage: "",
    sortBy: GBsortByFixtureOptions[1].key,
    sorting: GBsortByFixtureOptions[1].key,
    ...initialFilterState,
  },
  fixturesListbyRoundId: {
    items: [],
    loading: true,
    errorNo: 0,
    errorMessage: "",
    ...initialFilterState,
  },
  marketList: {
    selectedItemIds: [],
    selectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: false,
    numberOfPages: 1,
    errorNo: 0,
    sortBy: gbsortByMarketOptions[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
  marketListForFixture: {
    selectedItemIds: [],
    selectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    updatePriorities: [],
    items: [],
    loading: false,
    numberOfPages: 1,
    errorNo: 0,
    sortBy: gbsortByMarketOptions[1].key,
    errorMessage: "",
    ...initialFilterState,
  },
  marketListForMarket: {
    selectedItemIds: [],
    selectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    errorNo: 0,
    sortBy: gbsortByMarketOptions[1].key,
    errorMessage: "",
    ...initialFilterState,
  },
  marketOptionsList: {
    selectedItemIds: [],
    updatingStatusIds: [],
    updateOptionsPriorities: [],
    parents: [],
    items: [],
    currentPage: 1,
    loading: true,
    errorMessage: "",
    numberOfPages: 1,
    sortBy: gbsortByMarketOptions[1].key,
    ...initialFilterState,
    eventId: "",
  },
  catogoriyList: {
    loading: true,
    updatedCompititionPriorities: [],
    errorNo: 0,
    errorMessage: "",
  },
  consoleGamingList: {
    consoleGames: [],
    consoleTypes: [],
    selectedItemIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    categoriesListSelectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    sortBy: sortByConsoleGaming[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
  createConsoleGaming: {
    loading: true,
    // updatedCompititionPriorities: [],
    errorNo: 0,
    errorMessage: "",
  },
  JPBetDetails: {
    items: [],
    loading: false,
    errorNo: 0,
    errorMessage: "",
  },
};

export const catogoriesSlice = createSlice({
  name: "gb-catogories",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setMarketCreationFilters: (state, action) => {
      state[action.payload.ItemListName].itemLimitsInPage =
        action.payload.itemLimitsInPage;
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;
      state[action.payload.ItemListName].currentPage =
        action.payload.currentPage;
      state[action.payload.ItemListName].sortBy = action.payload.sortBy;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupBetCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.catogoriesList = action.payload;
      })
      .addCase(fetchGroupBetCategories.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateGBCategoryStatusBySportId.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateGBCategoryStatusBySportId.fulfilled, (state, action) => {
        state.loading = false;
        state.catogoriesList.errorNo = action.payload.errorNo;
        state.catogoriesList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateGBCategoryStatusBySportId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetMarkets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetMarkets.fulfilled, (state, action) => {
        state.loading = false;
        state.marketList = action.payload;
      })
      .addCase(fetchGroupBetMarkets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetCompetitions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetCompetitions.fulfilled, (state, action) => {
        state.loading = false;
        state.compititionList = action.payload;
      })
      .addCase(fetchGroupBetCompetitions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateGBCompetitionStatusByCompititionId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        UpdateGBCompetitionStatusByCompititionId.fulfilled,
        (state, action) => {
          state.loading = false;
          state.compititionList.errorNo = action.payload.errorNo;
          state.compititionList.errorMessage = action.payload.errorMessage;
        }
      )
      .addCase(UpdateGBCompetitionStatusByCompititionId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetFixtures.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetFixtures.fulfilled, (state, action) => {
        state.loading = false;
        state.fixturesList = action.payload;
      })
      .addCase(fetchGroupBetFixtures.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetFixturesParentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchGroupBetFixturesParentDetails.fulfilled,
        (state, action) => {
          state.loading = false;
          state.fixturesList.parents = action.payload.parents;
        }
      )
      .addCase(fetchGroupBetFixturesParentDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetMarketFixtureItemCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchGroupBetMarketFixtureItemCount.fulfilled,
        (state, action) => {
          state.loading = false;
          state.marketList.MarketingTabs = action.payload.MarketingTabs;
        }
      )
      .addCase(fetchGroupBetMarketFixtureItemCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateGBEventStatusByEventId.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateGBEventStatusByEventId.fulfilled, (state, action) => {
        state.loading = false;
        state.fixturesList.errorNo = action.payload.errorNo;
        state.fixturesList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateGBEventStatusByEventId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateGroupBetMarketStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateGroupBetMarketStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.marketListForFixture.errorNo = action.payload.errorNo;
        state.marketListForFixture.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateGroupBetMarketStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCreateUpdateMarkets.pending, (state) => {
        state.marketList.loading = true;
      })
      .addCase(fetchCreateUpdateMarkets.fulfilled, (state, action) => {
        state.marketList.loading = false;
        state.marketList.errorNo = action.payload.errorNo;
        state.marketList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchCreateUpdateMarkets.rejected, (state) => {
        state.marketList.loading = false;
      })
      .addCase(updateCategoriesFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategoriesFilters.fulfilled, (state, action) => {
        state.loading = false;
        state[action.payload.itemList].selectedItemIds =
          action.payload.selectedItemIds;
      })
      .addCase(updateCategoriesFilters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGroupBetMarketsForFixture.pending, (state) => {
        state.marketListForFixture.loading = true;
      })
      .addCase(getGroupBetMarketsForFixture.fulfilled, (state, action) => {
        state.marketListForFixture.loading = false;
        state.marketListForFixture = action.payload;
      })
      .addCase(getGroupBetMarketsForFixture.rejected, (state) => {
        state.marketListForFixture.loading = false;
      })
      .addCase(getGroupBetBaseMarkets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupBetBaseMarkets.fulfilled, (state, action) => {
        state.loading = false;
        state.marketListForFixture = action.payload;
      })
      .addCase(getGroupBetBaseMarkets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateGBMarketOptionStatusById.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateGBMarketOptionStatusById.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateGBMarketOptionStatusById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGroupBetMarketOption.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupBetMarketOption.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList = action.payload;
      })
      .addCase(getGroupBetMarketOption.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateGBMarketOptionPriorities.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGBMarketOptionPriorities.fulfilled, (state, action) => {
        state.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateGBMarketOptionPriorities.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateGBMarketPriorities.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGBMarketPriorities.fulfilled, (state, action) => {
        state.loading = false;
        state.marketListForFixture.errorNo = action.payload.errorNo;
        state.marketListForFixture.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateGBMarketPriorities.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateJPMarketPriorities.pending, (state) => {
        state.marketListForFixture.loading = true;
      })
      .addCase(updateJPMarketPriorities.fulfilled, (state, action) => {
        state.marketListForFixture.loading = false;
        state.marketListForFixture.errorNo = action.payload.errorNo;
        state.marketListForFixture.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateJPMarketPriorities.rejected, (state) => {
        state.marketListForFixture.loading = false;
      })
      .addCase(updateJPMarketOptionPriorities.pending, (state) => {
        state.marketOptionsList.loading = true;
      })
      .addCase(updateJPMarketOptionPriorities.fulfilled, (state, action) => {
        state.marketOptionsList.loading = false;
        state.marketOptionsList.errorNo = action.payload.errorNo;
        state.marketOptionsList.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateJPMarketOptionPriorities.rejected, (state) => {
        state.marketOptionsList.loading = false;
      })
      .addCase(updateCompititionPriorities.pending, (state) => {
        state.catogoriyList.loading = true;
      })
      .addCase(updateCompititionPriorities.fulfilled, (state, action) => {
        state.catogoriyList.loading = false;
        state.catogoriyList.errorNo = action.payload.errorNo;
        state.catogoriyList.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateCompititionPriorities.rejected, (state) => {
        state.catogoriyList.loading = false;
      })
      .addCase(getConsoleGaming.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConsoleGaming.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList = action.payload;
      })
      .addCase(getConsoleGaming.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createConsoleGameBet.pending, (state) => {
        state.loading = true;
      })
      .addCase(createConsoleGameBet.fulfilled, (state, action) => {
        state.loading = false;
        state.createConsoleGaming.errorNo = action.payload.errorNo;
        state.createConsoleGaming.errorMessage = action.payload.errorMessage;
      })
      .addCase(createConsoleGameBet.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createJackPotBet.pending, (state) => {
        state.JPBetDetails.loading = true;
      })
      .addCase(createJackPotBet.fulfilled, (state, action) => {
        state.JPBetDetails.loading = false;
        state.JPBetDetails.errorNo = action.payload.errorNo;
        state.JPBetDetails.errorMessage = action.payload.errorMessage;
      })
      .addCase(createJackPotBet.rejected, (state) => {
        state.JPBetDetails.loading = false;
      })
      .addCase(createUpdateRound.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUpdateRound.fulfilled, (state, action) => {
        state.loading = false;
        state.fixturesList.errorNo = action.payload.errorNo;
        state.fixturesList.errorMessage = action.payload.errorMessage;
      })
      .addCase(createUpdateRound.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFixturesforRound.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFixturesforRound.fulfilled, (state, action) => {
        state.loading = false;
        state.fixturesListbyRoundId.errorNo = action.payload.errorNo;
        state.fixturesListbyRoundId.errorMessage = action.payload.errorMessage;
      })
      .addCase(getFixturesforRound.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { updateSelectedItemIds, setMarketCreationFilters } =
  catogoriesSlice.actions;
export default catogoriesSlice.reducer;
