import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCreateUpdateOptions,
  fetchGroupBetMarketOptions,
  updateMarketOptionFilters,
  setMarketsOptionFilters,
  updateGroupBetMarketOptionStatusById,
  deleteGroupBetMarketOptions,
  fetchCreateUpdateOptionsforMarket,

  getConsoleGaming,
} from "../../../utility/thunks";
import {
  MarketCreationTabs,
  GBItemType,
  ItemLimitsInPage,
  gbsortByMarketOptions,
  sortByConsoleGaming
} from "../../../commons";

export const initialFilterState = {
  searchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList:"",
};

const initialState = {
  loading: true,
  consoleGamingList: {
    selectedItemIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    categoriesListSelectedCountry: "",
    updatingStatusIds: [],
    updatingHandshakeIds: [],
    items: [],
    loading: true,
    numberOfPages: 1,
    sortBy: sortByConsoleGaming[0].key,
    errorMessage: "",
    ...initialFilterState,
  },
};

export const consoleGamingSlice = createSlice({
  name: "console-gaming",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setMarketOptionFilters: (state, action) => {
      // state[action.payload.ItemListName].itemLimitsInPage = action.payload.itemLimitsInPage;
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;
      state[action.payload.ItemListName].currentPage =  action.payload.currentPage;
      state[action.payload.ItemListName].sortBy =  action.payload.sortBy;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateUpdateOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCreateUpdateOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList.errorNo = action.payload.errorNo;
        state.consoleGamingList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchCreateUpdateOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroupBetMarketOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroupBetMarketOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList = action.payload;
      })
      .addCase(fetchGroupBetMarketOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateMarketOptionFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMarketOptionFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList.selectedItemIds = action.payload.selectedItemIds;
      })
      .addCase(updateMarketOptionFilters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(setMarketsOptionFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(setMarketsOptionFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList.searchTerm = action.payload.searchTerm;
        state.consoleGamingList.currentPage = action.payload.currentPage;
        state.consoleGamingList.itemLimitsInPage = action.payload.itemLimitsInPage;
        state.consoleGamingList.sortBy =  action.payload.sortBy;
        // state.consoleGamingList.sorting =  action.payload.sortBy;
      })
      .addCase(setMarketsOptionFilters.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateGroupBetMarketOptionStatusById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGroupBetMarketOptionStatusById.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList.errorNo = action.payload.errorNo;
        state.consoleGamingList.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateGroupBetMarketOptionStatusById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteGroupBetMarketOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGroupBetMarketOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.consoleGamingList.errorNo = action.payload.errorNo;
        state.consoleGamingList.errorMessage = action.payload.errorMessage;
      })
      .addCase(deleteGroupBetMarketOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.pending, (state) => {
        state.consoleGamingList.loading = false;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.fulfilled, (state, action) => {
        state.consoleGamingList.loading = true;
        state.consoleGamingList.errorNo = action.payload.errorNo;
        state.consoleGamingList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchCreateUpdateOptionsforMarket.rejected, (state) => {
        state.consoleGamingList.loading = true;
      })
      ;
  },
});

export const { updateSelectedItemIds,setMarketOptionFilters} = consoleGamingSlice.actions;
export default consoleGamingSlice.reducer;
