import {
  GET_RECEIVER_HISTORY_SUCCESS,
  GET_SENDER_HISTORY_SUCCESS,
  GET_SENDER_RECEIVER_HISTORY_FAIL,
  GET_SENDER_RECEIVER_HISTORY_REQUEST
} from "../../actions/messenger/constants";

const initialState = {
  exportLoader: false,
  loading: false,
  senders: [],
  receivers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SENDER_RECEIVER_HISTORY_REQUEST:
      return { ...state, loading: true };
    case GET_SENDER_HISTORY_SUCCESS:
      const senders = action.payload.reduce((i, j) => {
        i.push({
          key: j.senderEmail,
          value: j.senderEmail,
          text: j.senderEmail
        });
        return i;
      }, []);
      return { ...state, loading: false, senders };
    case GET_RECEIVER_HISTORY_SUCCESS:
      const receivers = action.payload.reduce((i, j) => {
        i.push({
          key: j.senderEmail,
          value: j.senderEmail,
          text: j.senderEmail
        });
        return i;
      }, []);
      return { ...state, loading: false, receivers   };
    case GET_SENDER_RECEIVER_HISTORY_FAIL:
      return { ...state, loading: false, senders: [], receivers: [] };
    default:
      return state;
  }
};
