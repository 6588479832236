import axios from "axios";

const defaultHeaders = {
  "Content-Type": "application/json"
};

const APIClient = axios.create({
  baseURL: process.env.REACT_APP_API_URI.trim(),
  timeout: 60 * 1000,
  headers: {
    ...defaultHeaders
  }
});

export const APIInterceptors = () => {
  APIClient.interceptors.request.use(
    config => {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user && user.jwt) {
        config["headers"] = {
          ...config.headers,
          //extra headers to client
          Authorization: "Bearer " + user.jwt
        };
      }
      return config;
    },
    Error => Promise.reject(Error)
  );

  APIClient.interceptors.response.use(
    response => response,
    Error => Promise.reject(Error)
  );
};

export default APIClient;
