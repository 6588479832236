const initState = {
  listSettingsData: [],
  locations: [],
  clientStatuses: [],
};

const listSettingsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GETLISTSETTINGS_SUCCESS":
      return { ...state, listSettingsData: payload };
    case "GETLOCATIONS_SUCCESS":
      return { ...state, locations: payload };
    case "GETCLIENTSTATUSES_SUCCESS":      
      return { ...state, clientStatuses: payload };
    default:
      return state;
  }
};

export default listSettingsReducer;
