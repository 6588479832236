import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../src/commons";
import { v4 as uuidv4 } from "uuid";

export const IRIS_SERV_URL = process.env.REACT_APP_IRIS_SERV_URL.trim();

export const generateReduxKey = ({
  itemType,
  mainTab,
  leagueTab,
  itemSeasonBetType,
}) =>
  `${mainTab}-${itemType}${leagueTab ? "-" + leagueTab : ""}${
    itemSeasonBetType ? "-" + itemSeasonBetType : ""
  }`;

  // export const simpleChecksum = (data) => {
  //   let checksum = 0;    
  //   const checksumString = JSON.stringify(data);
  //   console.log(checksumString);
  //   // Iterate through each character in the data
  //   for (let i = 0; i < checksumString.length; i++) {
  //     // Add the ASCII value of 
  //     //  the character to the checksum
  //     checksum += checksumString.charCodeAt(i);
  //     console.log("checksum loop",checksumString.charCodeAt(i) + " = " + checksumString.charAt(i));
  //   }
  //   // Ensure the checksum is within 
  //   //the range of 0-255 by using modulo    
  //   //return checksum % 256;
  //   console.log("Checksum: " + (checksum));
  //   //console.log("Checksum modulo: " + (checksum % 256));
  //   return checksum;
  // }

  export const simpleChecksum = (data) => {
    let checksum = 0;    
    const checksumString = JSON.stringify(data);    
    for (let i = 0; i < checksumString.length; i++) {      
      checksum += checksumString.charCodeAt(i);      
    }
    return checksum;
  }

export const fetchCreateUpdateOptions = createAsyncThunk(
  "gb-options/fetchCreateUpdateOptions",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CreateUpdateOptions`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          optionId: gbdata.optionId,
          name: gbdata.name,
          isEnabled: gbdata.isEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          childOptions: gbdata.childOptions,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCreateUpdateOptionsforMarket = createAsyncThunk(
  "gb-options/fetchCreateUpdateOptionsforMarket",
  async (gbData, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CreateUpdateOptionsForMarket`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbData.multiCountryRegionCode,
          marketId: gbData.marketId,
          parentOptionIds: gbData.parentOptionIds ? gbData.parentOptionIds : [],
          options: gbData.options,
          optionName: gbData.optionName,
          selectionName: gbData.selectionName ? gbData.selectionName : "",
          isEnabled: gbData.isEnabled,
          selectionId: gbData.selectionId ? gbData.selectionId : "",
          eventId: gbData.eventId,
          lastUpdatedBy: gbData.lastEditedBy,
        },
      }); //
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteGroupBetMarketOptions = createAsyncThunk(
  "gb-options/deleteGroupBetMarketOptions",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/DeleteGroupBetMarketOptions`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          childOptionId: gbdata.childOptionId,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
// need to check duplicated api calls
export const updateGroupBetMarketOptionStatusById = createAsyncThunk(
  "gb-catogories/updateGroupBetMarketOptionStatusById",
  async (gbData, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGroupBetMarketOptionStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbData.ids,
          isGroupBetEnabled: gbData.isGroupBetEnabled,
          multiCountryRegionCode: gbData.multiCountryRegionCode,
          lastUpdatedBy: gbData.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// need to check duplicated api calls

export const UpdateGBMarketOptionStatusById = createAsyncThunk(
  "gb-catogories/updateGBMarketOptionStatusById",
  async (gbData, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGroupBetMarketOptionStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbData.ids,
          selectionIds: gbData.selectionIds,
          isGroupBetEnabled: gbData.isGroupBetEnabled,
          multiCountryRegionCode: gbData.multiCountryRegionCode,
          lastUpdatedBy: gbData.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// update GB Market Priorities
export const updateGBMarketPriorities = createAsyncThunk(
  "gb-catogories/updateGBMarketPriorities",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateMarketPriorities`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          marketId: gbdata.marketId,
          eventId: gbdata.eventId,
          priority: gbdata.priority,
          lastUpdatedBy: gbdata.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// update GB Market Option Priorities
export const updateGBMarketOptionPriorities = createAsyncThunk(
  "gb-catogories/updateGBMarketOptionPriorities",
  async (gbData, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateOptionPriorities`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          marketId: gbData.marketId,
          optionId: gbData.optionId,
          priority: gbData.priority,
          lastUpdatedBy: gbData.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCreateUpdateMarkets = createAsyncThunk(
  "gb-options/fetchCreateUpdateMarkets",
  async (gbData, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CreateUpdateMarkets`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          marketId: gbData.marketId,
          eventId: gbData.eventId,
          marketName: gbData.marketName,
          isEnabled: gbData.isEnabled,
          multiCountryRegionCode: gbData.multiCountryRegionCode,
          competitionId: gbData.competitionId,
          parentOptionId: gbData.parentOptionId,
          IsPlayerList: gbData.isPlayerList,
          lastUpdatedBy: gbData.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchResultGroupBetMarket = createAsyncThunk(
  "gb-resulting/fetchResultGroupBetMarket",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/ResultGroupBetMarket`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          betIds: gbdata.betIds,
          marketId: gbdata.marketId,
          winningOptionIds: gbdata.winningOptionIds,
          voidOptionIds: gbdata.golfBet ? [] : gbdata.voidOptionIds,
          isNoWinner: false,
        },
      });
      const prevState = getState().gbResulting;
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchReverseGroupBetMarket = createAsyncThunk(
  "gb-resulting/fetchReverseGroupBetMarket",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/ReverseGroupBetMarketResult`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          betIds: gbdata.betIds,
          marketId: gbdata.marketId,
        },
      });
      const prevState = getState().gbResulting;
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//MarketOptions
export const fetchGroupBetMarketOptions = createAsyncThunk(
  "gb-options/fetchGroupBetMarketOptions",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetMarketOptions`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          offset: gbdata.offset,
          searchTerm: gbdata.searchTerm,
          sorting: gbdata.sorting,
        },
      });
      const prevState = getState().gbMarketOptions.marketOptionsList;
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        count: response.data.count,
        errorNo: response.data.currentPage.errorNo,
        itemsCount: response.data.itemsCount,
        limit: response.data.limit,
        nextOffset: response.data.nextOffset,
        numberOfPages: response.data.numberOfPages,
        offset: response.data.offset,
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const updateMarketOptionFilters = createAsyncThunk(
  "gb-options/updateMarketOptionFilters",
  async (gbdata, { getState }) => {
    try {
      const prevState = getState().gbMarketOptions.marketOptionsList;
      return {
        selectedItemIds: gbdata.selectedItemIds,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const updateCategoriesFilters = createAsyncThunk(
  "gb-catogories/updateCategoriesFilters",
  async (gbdata, { getState }) => {
    try {
      return {
        selectedItemIds: gbdata.selectedItemIds,
        selectedItemIds: gbdata.reduxKey,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const setMarketsOptionFilters = createAsyncThunk(
  "gb-options/setMarketsOptionFilters",
  async (gbdata, { getState }) => {
    try {
      const prevState = getState().gbMarketOptions.marketOptionsList;
      return {
        searchTerm: gbdata.searchTerm,
        currentPage: gbdata.currentPage,
        itemLimitsInPage: gbdata.itemLimitsInPage,
      };
    } catch (error) {
      throw error;
    }
  }
);

//Markets old
export const fetchGroupBetMarkets = createAsyncThunk(
  "gb-catogories/fetchGroupBetMarkets",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetMarkets`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          competitionId: gbdata.competitionId,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          offset: gbdata.offset,
          searchTerm: gbdata.searchTerm,
          sorting: gbdata.sorting,
        },
      });
      const prevState = getState().gbCatogories.marketList;
      const reduxKey = generateReduxKey({ ...gbdata });
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        count: response.data ? response.data.count : 0,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        MarketingTabs:
          response.data && response.data.MarketingTabs
            ? response.data.MarketingTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sortBy: prevState.sortBy,
        errorNo: 1,
      };
    } catch (error) {
      throw error;
    }
  }
);

//Get GroupBet Market Details
export const getGroupBetMarketOption = createAsyncThunk(
  "gb-catogories/getGroupBetMarketOption",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetMarketDetails`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          marketId: gbdata.marketId,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          offset: gbdata.offset,
          searchTerm: gbdata.searchTerm,
          sorting: gbdata.sorting,
        },
      });
      const prevState = getState().gbCatogories.marketOptionsList;
      const reduxKey = generateReduxKey({ ...gbdata });
      return {
        loading: false,
        items: response.data ? response.data.options : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        count: response.data ? response.data.count : 0,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        MarketingTabs:
          response.data && response.data.MarketingTabs
            ? response.data.MarketingTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
        eventId: response.data.eventId,
        errorNo: 1,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchGroupBetMarketFixtureItemCount = createAsyncThunk(
  "gb-catogories/fetchGroupBetMarketFixtureItemCount",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetMarkets`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          competitionId: gbdata.competitionId,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          offset: gbdata.offset,
          searchTerm: gbdata.searchTerm,
        },
      });

      return {
        loading: false,
        MarketingTabs:
          response.data && response.data.MarketingTabs
            ? response.data.MarketingTabs
            : [],
      };
    } catch (error) {
      throw error;
    }
  }
);
//tipping

export const UpdateTippingCategoryStatus = createAsyncThunk(
  "tipping/UpdateTippingCategoryStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateTippingCategoriesStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          tippingEnabled: gbdata.tippingEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateTippingCompetitionStatus = createAsyncThunk(
  "tipping/UpdateTippingCompetitionStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateTippingCompetitionsStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          tippingEnabled: gbdata.tippingEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          LeagueCompetitionId : gbdata.LeagueCompetitionId,
          marketTypeId : gbdata.marketTypeId, 
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTippingRounds = createAsyncThunk(
  "tipping/fetchTippingRounds",
  async (gbdata, { getState }) => {
    try {      
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/TippingBetRounds`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          competitionId: gbdata.competitionId,
          limit: gbdata.limit,
          searchTerm: gbdata.searchTerm,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          tab: gbdata.tab,
          filterUpdated: gbdata.filterUpdated,
        },
      });
      const prevState = getState().tippingData.roundList;
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        responseSearchTerm:response.data.responseSearchTerm,
        filterDateFrom:prevState.filterDateFrom,
        filterDateTo:prevState.filterDateTo,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateTippingRoundsStatus = createAsyncThunk(
  "tipping/UpdateTippingRoundsStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateTippingRoundStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          tippingEnabled: gbdata.tippingEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTipingFixtures = createAsyncThunk(
  "tipping/fetchTipingFixtures",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/TippingFixture`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          competitionId: gbdata.competitionId,
          roundId: gbdata.roundId,
          tab: gbdata.tab,
          limit: gbdata.limit,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          searchTerm: gbdata.searchTerm,
          filterUpdated: gbdata.filterUpdated,
        },
      });
      const prevState = getState().tippingData.tippingFixtureList;
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        MarketingTabs:
          response.data && response.data.MarketingTabs
            ? response.data.MarketingTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        filterDateFrom:prevState.filterDateFrom,
        filterDateTo:prevState.filterDateTo,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sorting,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateTippingEventStatus = createAsyncThunk(
  "tipping/UpdateTippingEventStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateTippingFixtureStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          tippingEnabled: gbdata.tippingEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//Catogories
export const fetchGroupBetCategories = createAsyncThunk(
  "gb-catogories/fetchGroupBetCategories",
  async (gbdata, { getState }) => {
    try {
      const prevState = getState().gbCatogories.catogoriesList;
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetCategories`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          searchTerm: gbdata.searchTerm,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          tab: gbdata.tab,
        },
      });
      //const reduxKey = generateReduxKey({ ...gbdata });
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

//Get Console Gaming list
export const getConsoleGaming = createAsyncThunk(
  "console-gaming/getConsoleGaming",
  async (gbdata, { getState }) => {
    try {
      const prevState = getState().gbCatogories.consoleGamingList;
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/getConsoleGameConfig`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          searchTerm: gbdata.searchTerm,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          tab: gbdata.tab,
        },
      });
      return {
        loading: false,
        consoleGames: response.data ? response.data.consoleGames : [],
        consoleTypes: response.data ? response.data.consoleTypes : [],
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

// Create Console Gaming Bet
export const createConsoleGameBet = createAsyncThunk(
  "gb-catogories/createConsoleGameBet",
  async (params, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/createConsoleGameBet`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: params.multiCountryRegionCode,
          consoleTypes: params.consoleTypes,
          consoleGameId: params.consoleGameId,
          statement: params.statement,
          amount: params.amount,
          minPlayers: params.minPlayers,
          maxPlayers: params.maxPlayers,
          lobbyCode: params.lobbyCode,
          scheduledDateTime: params.scheduledDateTime,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Start Console Gaming Bet
export const startConsoleGameBet = createAsyncThunk(
  "gb-catogories/startConsoleGameBet",
  async (params, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/startConsoleGame`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: params.multiCountryRegionCode,
          // startTime: params.startTime,
          // finishTime: params.finishTime,
          betId: params.betId,
          lobbyCode: params.lobbyCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Declare Winner Console Gaming

export const declareConsoleGame = createAsyncThunk(
  "gb-catogories/declareConsoleGame",
  async (params, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/ResultConsoleGame`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          betIds: params.betIds,
          mediaFile: params.mediaFile,
          mediaContentType:  params.mediaContentType,
          mediaFileName: params.mediaFileName,
          mediaFileBase: params.mediaFileBase,
          mediaImageType: params.mediaImageType,
          winningOptionIds: params.winningOptionIds,
          comments:  params.comments,
          multiCountryRegionCode: params.multiCountryRegionCode,
          marketId: params.marketId,
          voidOptionIds: [],
          isNoWinner: false,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateConsoleGamingStatus = createAsyncThunk(
  "gb-catogories/UpdateConsoleGamingStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateConsoleGamingStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          marketId: gbdata.marketId,
          competitionId:gbdata.competitionId,
          isGroupBetEnabled: gbdata.isGroupBetEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          lastUpdatedBy: gbdata.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
//Get Console Gaming 
export const setConsoleGameType = createAsyncThunk(
  "console-gaming/getConsoleGaming",
  async (gbdata, { getState }) => {
    try {
      const prevState = getState().gbCatogories.consoleGamingList;
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/setConsoleGameType`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          searchTerm: gbdata.searchTerm,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          tab: gbdata.tab,
        },
      });
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);
export const UpdateGBCategoryStatusBySportId = createAsyncThunk(
  "gb-catogories/UpdateGBCategoryStatusBySportId",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGroupBetCategoriesStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          isGroupBetEnabled: gbdata.isGroupBetEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//Compititions
export const fetchGroupBetCompetitions = createAsyncThunk(
  "gb-catogories/fetchGroupBetCompetitions",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetCompetitions`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          sportId: gbdata.sportId,
          limit: gbdata.limit,
          searchTerm: gbdata.searchTerm,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          tab: gbdata.tab,
        },
      });
      const prevState = getState().gbCatogories.compititionList;
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sortBy,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateGBCompetitionStatusByCompititionId = createAsyncThunk(
  "gb-catogories/UpdateGBCompetitionStatusByCompititionId",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGroupBetCompetitionStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          isGroupBetEnabled: gbdata.isGroupBetEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//Fixtures
export const fetchGroupBetFixtures = createAsyncThunk(
  "gb-catogories/fetchGroupBetFixtures",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetFixtures`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          competetionId: gbdata.competetionId,
          tab: gbdata.tab,
          limit: gbdata.limit,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          searchTerm: gbdata.searchTerm,
        },
      });
      const prevState = getState().gbCatogories.fixturesList;
      return {
        loading: false,
        items: response.data ? response.data.items : [],
        numberOfPages: response.data ? response.data.numberOfPages : 1,
        currentPage: response.data ? response.data.currentPage : 1,
        parents: response.data ? response.data.parents : [],
        competitionCountries:
          response.data && response.data.competitionCountries
            ? response.data.competitionCountries
            : [],
        competitionTabs:
          response.data && response.data.competitionTabs
            ? response.data.competitionTabs
            : [],
        MarketingTabs:
          response.data && response.data.MarketingTabs
            ? response.data.MarketingTabs
            : [],
        selectedCountry:
          response.data &&
          response.data.competitionCountries &&
          response.data.competitionCountries.find((i) => i.isSelected)
            ? response.data.competitionCountries.find((i) => i.isSelected).key
            : "",
        sortingFields: (response.data && response.data.sortingFields) || [],
        selectedItemIds: prevState.selectedItemIds,
        updatingStatusIds: prevState.updatingStatusIds,
        itemLimitsInPage: prevState.itemLimitsInPage,
        searchTerm: prevState.searchTerm,
        itemList: gbdata.itemList,
        sorting: prevState.sorting,
        sortBy: prevState.sortBy,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchGroupBetFixturesParentDetails = createAsyncThunk(
  "gb-catogories/fetchGroupBetFixturesParentDetails",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GroupBetFixtures`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          competetionId: gbdata.competetionId,
          tab: gbdata.tab,
          limit: gbdata.limit,
          offset: gbdata.offset,
          sorting: gbdata.sorting,
          searchTerm: gbdata.searchTerm,
        },
      });

      return {
        loading: false,
        parents: response.data ? response.data.parents : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateGBEventStatusByEventId = createAsyncThunk(
  "gb-catogories/UpdateGBEventStatusByEventId",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGroupBetEventStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          isGroupBetEnabled: gbdata.isGroupBetEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateGroupBetMarketStatus = createAsyncThunk(
  "gb-catogories/UpdateGroupBetMarketStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGroupBetMarketStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          ids: gbdata.ids,
          marketId: gbdata.marketId,
          competitionId:gbdata.competitionId,
          isGroupBetEnabled: gbdata.isGroupBetEnabled,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          lastUpdatedBy: gbdata.lastEditedBy,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const setResultingFilters = createAsyncThunk(
  "gb-resulting/setResultingFilters",
  async (gbdata, { getState }) => {
    try {
      const prevState = getState().gbResulting.activeMarketsList;
      return {
        searchTerm: gbdata.searchTerm,
        currentPage: gbdata.currentPage,
        itemLimitsInPage: gbdata.itemLimitsInPage,
      };
    } catch (error) {
      throw error;
    }
  }
);
export const fetchGroupBetActiveMarkets = createAsyncThunk(
  "gb-resulting/fetchGroupBetActiveMarkets",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetMarketsForResults`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          limit: gbdata.limit,
          offset: gbdata.offset,
          tab: gbdata.tab,
          fixuteId: gbdata.fixuteId,
          competitionId: gbdata.competitionId,
          sportId: gbdata.sportId,
        },
      });
      const prevState = getState().gbResulting;
      return {
        items: response.data.items,
        count: response.data.count,
        currentPage: response.data.currentPage,
        errorNo: response.data.currentPage.errorNo,
        itemsCount: response.data.itemsCount,
        limit: response.data.limit,
        nextOffset: response.data.nextOffset,
        numberOfPages: response.data.numberOfPages,
        offset: response.data.offset,
        itemLimitsInPage: prevState.activeMarketsList.itemLimitsInPage,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMultipleFixtures = createAsyncThunk(
  "gb-resulting/fetchMultipleFixtures",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/multiple-fixtures`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          competitionId: gbdata.competitionId,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      const prevState = getState().gbResulting;
      return {
        items: response.data,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchBetDetailsByBetId = createAsyncThunk(
  "gb-resulting/fetchBetDetailsByBetId",
  async (gbdata, { getState }) => {
    try {
      const response = await axios.get(`${IRIS_SERV_URL}/bet/${gbdata}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      return {
        betId: response.data.betId,
        amount: response.data.amount,
        winAmount: response.data.winAmount,
        sportId: response.data.sportId,
        leagueId: response.data.leagueId,
        seasonId: response.data.seasonId,
        roundId: response.data.roundId,
        eventId: response.data.eventId,
        sport: response.data.sport,
        league: response.data.league,
        createdByClientId: response.data.createdByClientId,
        createdByClient: response.data.createdByClient,
        acceptedByClientId: response.data.acceptedByClientId,
        fullStatement: response.data.fullStatement,
        betStatus: response.data.betStatus,
        betVisibility: response.data.betVisibility,
        betActivities: response.data.betActivities,
        invitees: response.data.invitees,
        location: response.data.location,
        createdDate: response.data.createdDate,
        betCloseDate: response.data.betCloseDate,
        eventStartDate: response.data.eventStartDate,
        currencyCode: response.data.currencyCode,
        betType: response.data.betType,
        viewCount: response.data.viewCount,
        season: response.data.season,
        round: response.data.round,
        event: response.data.event,
        isGroupBet: response.data.isGroupBet,
        isGoS: response.data.isGoS,
        groupBetData: response.data.groupBetData,
        chatId: response.data.chatId,
        commentsCount: response.data.commentsCount,
        isSupervisorBet: response.data.isSupervisorBet,
        isTippingComp: response.data.isTippingComp,
        betFeature: response.data.betFeature,
        betFinishDate: response.data.betFinishDate,
        
      };
    } catch (error) {
      throw error;
    }
  }
);

export const CancelGroupBet = createAsyncThunk(
  "gb-resulting/CancelGroupBet",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CancelGroupBet`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          betIds: gbdata.betIds,
          reason: gbdata.reason,
          comments: gbdata.comments,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Get Market Result by ID
export const GetMarketResultbyID = createAsyncThunk(
  "gb-resulting/GetSingleMarketForResults",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetSingleMarketForResults`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          tab: gbdata.tab,
          marketId: gbdata.marketId,
        },
      });
      const prevState = getState().gbResulting;
      return {
        items: response.data.items,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const getGroupBetMarketsForFixture = createAsyncThunk(
  "gb-catogories/getGroupBetMarketsForFixture",
  async (gbdata, { getState }) => {
    try {
      if (gbdata && gbdata.fixtureId) {
        const response = await axios({
          method: "POST",
          url: `${IRIS_SERV_URL}/GroupBetMarketsForFixture`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            competitionId: gbdata.competitionId,
            fixtureId: gbdata.fixtureId,
            eventId: gbdata.eventId,
            multiCountryRegionCode: gbdata.multiCountryRegionCode,
            limit: gbdata.limit,
            offset: gbdata.offset,
            searchTerm: gbdata.searchTerm,
            sorting: gbdata.sorting,
          },
        });

        const prevState = getState().gbCatogories.marketListForFixture;
        const reduxKey = generateReduxKey({ ...gbdata });
        return {
          loading: false,
          items: response.data ? response.data.items : [],
          numberOfPages: response.data ? response.data.numberOfPages : 1,
          currentPage: response.data ? response.data.currentPage : 1,
          count: response.data ? response.data.count : 0,
          parents: response.data ? response.data.parents : [],
          competitionCountries:
            response.data && response.data.competitionCountries
              ? response.data.competitionCountries
              : [],
          competitionTabs:
            response.data && response.data.competitionTabs
              ? response.data.competitionTabs
              : [],
          MarketingTabs:
            response.data && response.data.MarketingTabs
              ? response.data.MarketingTabs
              : [],
          selectedCountry:
            response.data &&
            response.data.competitionCountries &&
            response.data.competitionCountries.find((i) => i.isSelected)
              ? response.data.competitionCountries.find((i) => i.isSelected).key
              : "",
          sortingFields: (response.data && response.data.sortingFields) || [],
          selectedItemIds: prevState.selectedItemIds,
          updatingStatusIds: prevState.updatingStatusIds,
          itemLimitsInPage: prevState.itemLimitsInPage,
          searchTerm: prevState.searchTerm,
          itemList: gbdata.itemList,
          sortBy: prevState.sortBy,
          errorNo: 1,
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getGroupBetBaseMarkets = createAsyncThunk(
  "gb-catogories/getGroupBetBaseMarkets",
  async (gbdata, { getState }) => {
    try {
      if (gbdata) {
        const response = await axios({
          method: "POST",
          url: `${IRIS_SERV_URL}/GroupBetMarkets`,
          headers: { Authorization: "Bearer " + getToken() },
          data: {
            competitionId: gbdata.competitionId,
            // fixtureId:gbdata.fixtureId,
            eventId: gbdata.eventId,
            multiCountryRegionCode: gbdata.multiCountryRegionCode,
            limit: gbdata.limit,
            offset: gbdata.offset,
            searchTerm: gbdata.searchTerm,
            sorting: gbdata.sorting,
          },
        });

        const prevState = getState().gbCatogories.marketListForMarket;
        const reduxKey = generateReduxKey({ ...gbdata });
        return {
          loading: false,
          items: response.data ? response.data.items : [],
          numberOfPages: response.data ? response.data.numberOfPages : 1,
          currentPage: response.data ? response.data.currentPage : 1,
          count: response.data ? response.data.count : 0,
          parents: response.data ? response.data.parents : [],
          competitionCountries:
            response.data && response.data.competitionCountries
              ? response.data.competitionCountries
              : [],
          competitionTabs:
            response.data && response.data.competitionTabs
              ? response.data.competitionTabs
              : [],
          MarketingTabs:
            response.data && response.data.MarketingTabs
              ? response.data.MarketingTabs
              : [],
          selectedCountry:
            response.data &&
            response.data.competitionCountries &&
            response.data.competitionCountries.find((i) => i.isSelected)
              ? response.data.competitionCountries.find((i) => i.isSelected).key
              : "",
          sortingFields: (response.data && response.data.sortingFields) || [],
          selectedItemIds: prevState.selectedItemIds,
          updatingStatusIds: prevState.updatingStatusIds,
          itemLimitsInPage: prevState.itemLimitsInPage,
          searchTerm: prevState.searchTerm,
          itemList: gbdata.itemList,
          sortBy: prevState.sortBy,
          errorNo: 1,
        };
      }
    } catch (error) {
      throw error;
    }
  }
);
export const fetchclientStatus = createAsyncThunk(
  "clients/fetchclientStatus",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/ClientStatuses`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return {
        items: response.data ? response.data.items : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const createUpdateClient = createAsyncThunk(
  "clients/createUpdateClient",
  async (gbdata, { getState }) => {
    try {
      const requestPayload ={         
        clientId: gbdata.clientId,
        avatar: gbdata.avatar,
        username: gbdata.username,
        email: gbdata.email,
        firstName: gbdata.firstName,
        lastName: gbdata.lastName,
        phone: gbdata.phone,
        clientStatusId: gbdata.clientStatusId,
        exclusionDays: gbdata.exclusionDays,
        exclusionStartDate: gbdata.exclusionStartDate,
        exclusionEndDate: gbdata.exclusionEndDate,
        rating: gbdata.rating,
        dob: gbdata.dob,
        consentToMarketing: gbdata.consentToMarketing,
        consentToNameSearch: gbdata.consentToNameSearch,
        consentToMarketingViaSms: gbdata.consentToMarketingViaSms,
        consentToMarketingViaEmail: gbdata.consentToMarketingViaEmail,          
        consentToShowMyBetsToMyFollowers:
          gbdata.consentToShowMyBetsToMyFollowers,
        unitAndHouseNumber: gbdata.unitAndHouseNumber,
        streetName: gbdata.streetName,
        city: gbdata.city,
        state: gbdata.state,
        postcode: gbdata.postcode,
        country: gbdata.country,
        isVerified: gbdata.isVerified,
        isPrivate: gbdata.isPrivate,
        bio: gbdata.bio,
        headerImage: gbdata.headerImage,
        isInfluencer: gbdata.isInfluencer,
        thirdPartySharingOptOut: gbdata.thirdPartySharingOptOut,
        isEmailVerified: gbdata.isEmailVerified,
        save: gbdata.save,
        IsEmailChange: gbdata.IsEmailChange,
        multiCountryRegionCode: gbdata.multiCountryRegionCode,
        pepSanctionrisk: gbdata.pepSanctionrisk,
        pepNotes: gbdata.pepNotes,
        pepHioAttestation:gbdata.pepHioAttestation,
        mfaEnabled:gbdata.mfaEnabled,
        tranRef:uuidv4().toUpperCase(),
      }      
      const tranCheck_header =simpleChecksum(requestPayload);
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CreateUpdateClient`,
        headers: { Authorization: "Bearer " + getToken() , 'tranCheck': tranCheck_header },
        data: requestPayload,
      });
      return {
        items: response.data ? response.data.items : [],
        errorNo: response.data ? response.data.errorNo : [],
        errorMessage: response.data ? response.data.errorMessage : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchClientDetailsbyClientId = createAsyncThunk(
  "clients/fetchClientDetailsbyClientId",
  async (clientId, { getState }) => {
    try {
      const response = await axios.get(`${IRIS_SERV_URL}/clients/${clientId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      return {
        items: response.data ? response.data : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAdminClientSelfExclusion = createAsyncThunk(
  "clients/fetchAdminClientSelfExclusion",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/AdminClientSelfExclusion`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          clientId: gbdata.clientId,
          dateFrom: gbdata.dateFrom,
          dateTo: gbdata.dateTo,
        },
      });
      return {
        items: response.data ? response.data.items : [],
        errorNo: response.data ? response.data.errorNo : [],
        errorMessage: response.data ? response.data.errorMessage : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const sendIntialEmailToSponsor = createAsyncThunk(
  "clients/SendIntialSponsorEmail",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/SendIntialSponsorEmail`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          auditLogId: gbdata.auditLogId,
          firstName: gbdata.sponsor.firstName,
          lastName: gbdata.sponsor.lastName,
          email: gbdata.sponsor.email,
          clientId: gbdata.clientId,
        },
      });
      return {
        items: response.data ? response.data.items : [],
        errorNo: response.data ? response.data.errorNo : [],
        errorMessage: response.data ? response.data.errorMessage : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const downloadSelfExclusionCSV = createAsyncThunk(
  "clients/DownloadSelfExclusionCSV",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/DownloadSelfExclusionCSV`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          clientId: gbdata.clientId,
          dateFrom: gbdata.dateFrom,
          dateTo: gbdata.dateTo,
        },
      });
      return {
        responseData: response.data ? response : [],
        errorNo: response.status ? response.status : [],
        errorMessage: response.statusText ? response.statusText : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const UpdateExclusionSponsorDetail = createAsyncThunk(
  "clients/UpdateExclusionSponsorDetail",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateExclusionSponsorDetail`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          auditLogId: gbdata.AuditLogId,
          firstName: gbdata.SponsorFirstName,
          lastName: gbdata.SponsorLastName,
          dob: gbdata.SponsorDateOfBirth,
          phone: gbdata.SponsorPhone,
          email: gbdata.SponsorEmail,
          comments: gbdata.Comments,
        },
      });
      return {
        items: response.data ? response.data.items : [],
        errorNo: response.data ? response.data.errorNo : [],
        errorMessage: response.data ? response.data.errorMessage : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchStateList = createAsyncThunk(
  "clients/fetchStateList",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/statesList`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
        },
      });
      return {
        items: response.data ? response.data.statesList : [],
        errorNo: response.data ? response.data.errorNo : [],
        errorMessage: response.data ? response.data.errorMessage : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMasterTeamList = createAsyncThunk(
  'categories/fetchMasterTeamList',
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${IRIS_SERV_URL}/master/teamList`,
        headers: { Authorization: `Bearer ${getToken()}` },
        data: {
          divisionId: gbdata.divisionId,
          competitionId: gbdata.competitionId,
          limit: gbdata.limit,
          searchTerm: gbdata.searchTerm,
          sorting: gbdata.sorting,
          region: gbdata.region,
          offset: (gbdata.currentPage - 1) * gbdata.limit,
          isActive: gbdata.isActive && gbdata.isActive ? gbdata.isActive : 0,
          filterUpdated: {
            from: gbdata.from,
            to: gbdata.to,
          },
        },
      });
      const currentState = getState().categoriesApp.teamData;
      const shouldUpdateResponse =
        gbdata.divisionId === currentState.divisionId &&
        gbdata.competitionId === currentState.competitionId && 
        gbdata.limit === currentState.limit &&
        gbdata.searchTerm === currentState.searchTerm &&
        gbdata.offset === currentState.offset;

      return {
        teamsList: shouldUpdateResponse
          ? [...currentState.teamsList, ...response.data.teamsList]
          : [...response.data.teamsList],
        errorNo: response.data ? response.data.errorNo : 1000,
        errorMessage: response.data ? response.data.errorMessage : '',
        count: response.data ? response.data.count : 0,
        totalCount: response.data ? response.data.totalCount : 0,
        currentPage: response.data ? response.data.currentPage : 0,
        numberofPages: response.data ? response.data.numberofPages : 0,
        totalDBCount: response.data ? response.data.totalDBCount : 0,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAdminClientActivity = createAsyncThunk(
  "clients/fetchAdminClientActivity",
  async (gbdata, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetClientActivity`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          clientId: gbdata.clientId,
          startDate: gbdata.startDate,
          endDate: gbdata.endDate,
          pageSize: gbdata.pageSize,
          pageNo: gbdata.pageNo,
        },
      });
      if (response.status === 200) {
        return {
          items: response.data ? response.data.activity : [],
          count: response.data ? response.data.count : 0,
          pageNo: response.data ? response.data.pageNo : 0,
          totalItems: response.data ? response.data.totalItems : 0,
          totalPages: response.data ? response.data.totalPages : 0,
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const processApproveRejectWithdrawals = createAsyncThunk(
  "withdrawals/processApproveRejectWithdrawals",
  async (data, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/Finance/ApproveRejectWithdrawalAction`,
        headers: { Authorization: "Bearer " + getToken() },
        data: data.withdrawalDetails.map(detail => ({
          withdrawalRequestId: detail.withdrawalRequestId,
          action: data.action,
          approvedAmount: detail.requestedAmount,
          comments: detail.comments,
        })),
      });
      if (response.status === 200) {
        return  response.data;        
      }
    } catch (error) {
      throw error;
    }
  }
);

export const reverseWithdrawals = createAsyncThunk(
  "withdrawals/reverseWithdrawals",
  async (data, { getState }) => {
    try{
      console.log('DATA HERE');
      console.log(data);
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/Finance/ReverseBatchWithdrawals`,
        headers: { Authorization: "Bearer " + getToken() },
        data: data
      });
      if (response.status === 200) {
        return  response.data;        
      }
    } catch (e) {
      throw e;
    }
  }
);

export const getWithdrawals = createAsyncThunk(
  "withdrawals/getWithdrawals",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/Finance/GetWithdrawals`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: data.multiCountryRegionCode,
          dateFrom: data.filter.dateFrom,
          dateTo: data.filter.dateTo,
          type: data.type,
          searchTerm: data.searchTerm,
          sorting: data.sorting,
        },
      });
      if (response.status === 200) {
        return {
          withDrawals: response.data ? response.data : [],
          errorNo: response.data ? response.data.errorNo : 1000,
          errorMessage: response.data ? response.data.errorMessage : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchfinanceAdjustment = createAsyncThunk(
  "withdrawals/fetchfinanceAdjustment",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/finance/adjustment-pending`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: data.multiCountryRegionCode,
          dateFrom: data.filter.dateFrom,
          dateTo: data.filter.dateTo,
          type: data.type,
          sorting: data.sorting,
        },
      });
      if (response.status === 200) {
        return {
          adjustmentList: response.data ? response.data : [],
          errorNo: response.data ? response.data.errorNo : 1000,
          errorMessage: response.data ? response.data.errorMessage : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getSystemSettingsConfig = createAsyncThunk(
  "configureSettings/getSystemSettingsConfig",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${IRIS_SERV_URL}/settings/config${data.isDefault ? '': data.multiCountryRegionCode}`,
        {
          headers: { Authorization: `Bearer ${getToken()}`, 'tranCheck': 0 },
        }
      );
      if (response.status === 200) {
        return {
          systemSettings: response.data ? response.data : [],
          countryStates: response.data ? response.data.countryStates : [],
          errorNo: response.data.errorNo ? response.data.errorNo : 1000,
          errorMessage: response.data.errorMessage
            ? response.data.errorMessage
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateSystemConfigSettings = createAsyncThunk(
  "configureSettings/updateSystemConfigSettings",
  async (data, thunkAPI) => {
    try {
      console.log(data.systemConfigs);
      const requestPayload ={ 
        ...data.systemConfigs,
           tranRef:uuidv4().toUpperCase(),
      }
      const tranCheck_header =simpleChecksum(requestPayload);
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/settings/config/${data.tab}${data.multiCountryRegionCode}`,
        headers: { Authorization: "Bearer " + getToken(), 'tranCheck': tranCheck_header },
        data: {
          systemConfig: requestPayload,
        },
      });

      if (response.status === 200) {
        return {
          errorNo: response.data ? response.data.errorNo : 1000,
          errorMessage: response.data ? response.data.errorMessage : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getBetListData = createAsyncThunk(
  "clients/getBetListData",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetBets`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: data.multiCountryRegionCode,
          start: data.start,
          end: data.end,
          products: data.products,
          types: data.types,
          dateFilterOn: data.dateFilterOn,
          Parent: data.parents,
          sport: data.sport,
          league: data.league,
          localFilter: data.localFilter,
          season: data.season,
          round: data.round,
          BetCurrencies: data.BetCurrencies,
          fixture: data.fixture,
          sorting: data.sorting,
        },
      });
      if (response.status === 200) {
        return {
          betData: response.data ? response.data : [],
          count: response.data ? response.data.length : 0,
          pageNo: response.data ? response.data.pageNo : 0,
          totalItems: response.data ? response.data.totalItems : 0,
          totalPages: response.data ? response.data.totalPages : 0,
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchPepList = createAsyncThunk(
  "clients/fetchPepList",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${IRIS_SERV_URL}/peprisksList`,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return {
        items: response.data ? response.data.risksList : [],
        errorNo: response.data ? response.data.errorNo : [],
        errorMessage: response.data ? response.data.errorMessage : [],
      };
    } catch (error) {
      throw error;
    }
  }
);

export const initiateManualwithdrawals = createAsyncThunk(
  "clients/initiateManualwithdrawals",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/Client/Transaction/ManualWithdrawal`,
        headers: { Authorization: "Bearer " + getToken() },
        data:  {          
          financialInstitutionNumber:data.financialInstitutionNumber,
          sortCode:data.sortCode,
          ibanNumber:data.ibanNumber,
          swiftBic:data.swiftBic,
          bsbNumber:data.clientBsb,
          accountNumber:data.accountNumber,
          accountName:data.accHolder,  
          amount:data.amount,
          clientId:data.clientId,
          bankName:data.bankName,
          accountType: data.accType,
          userId:data.userId,
          address: [
            {
              addressLine1: data.addressLine1,
              city: data.city,
              provinceORterritory: data.provinceORterritory,
              postalORZipCode: data.postalORZipCode,
              country: data.country,
            },
          ],
        },
      });
      if (response.status === 200) {
        return {
          items: response.data ? response.data : [],
          errorNo: response.data
            ? response.data.errorNo
              ? response.data.errorNo
              : 0
            : 0,
          errorMessage: response.data
            ? response.data.message
              ? response.data.message
              : ""
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPendingBets = createAsyncThunk(
  "clients/getPendingBets",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/PendingBets`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: data.multiCountryRegionCode,
          start: data.start,
          end: data.end,
          products: data.products,
          types: data.types,
          dateFilterOn: data.dateFilterOn,
          Parent: data.parents,
          sport: data.sport,
          league: data.league,
          localFilter: data.localFilter,
          season: data.season,
          round: data.round,
          fixture: data.fixture,
        },
      });
      if (response.status === 200) {
        return {
          pendingBets: response.data ? response.data : [],
          count: response.data ? response.data.length : 0,
          pageNo: response.data ? response.data.pageNo : 0,
          totalItems: response.data ? response.data.totalItems : 0,
          totalPages: response.data ? response.data.totalPages : 0,
          errorNo: response.data
            ? response.data.errorNo
              ? response.data.errorNo
              : 0
            : 0,
          errorMessage: response.data
            ? response.data.errorMessage
              ? response.data.errorMessage
              : ""
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const rewardBonustoClients = createAsyncThunk(
  "clients/rewardBonustoClients",
  async (data, thunkAPI) => {
    try {
      const requestPayload = {
        ClientIds: data.ClientIds,
        currencyCode: data.currencyCode,
        Amount: data.Amount,
        ExpiryDate: data.ExpiryDate,
        AllActiveClients: data.AllActiveClients,
        tranRef: uuidv4().toUpperCase(),
      }
      const tranCheck_header =simpleChecksum(requestPayload);
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/bonus/reward`,
        headers: { Authorization: "Bearer " + getToken(), 'tranCheck': tranCheck_header },
        data: requestPayload,
      });
      if (response.status === 200) {
        return {
          rewardbonusResponse: response.data ? response.data : [],
          errorNo: response.data
            ? response.data.errorNo
              ? response.data.errorNo
              : 0
            : 0,
          errorMessage: response.data
            ? response.data.message
              ? response.data.message
              : ""
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const confirmGroupBetResult = createAsyncThunk(
  "gb-resulting/confirmGroupBetResult",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/SettleBetRequest`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          betIds: gbdata.betIds,
          winner: gbdata.winner,
          winnerClientID: gbdata.winningOptionIds,
          isNoWinner: gbdata.isNoWinner,
        },
      });
      const prevState = getState().gbResulting;
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// update GB Market Priorities
export const updateJPMarketPriorities = createAsyncThunk(
  "gb-catogories/updateJPMarketPriorities",
  async (gbdata, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGBMarketPriorities`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          lastUpdatedBy: gbdata.lastUpdatedBy,
          multiCountryRegionCode: gbdata.multiCountryRegionCode,
          competitionId:gbdata.competitionId,
          fixtureId:gbdata.fixtureId,
          priorityRange:gbdata.priorityRange,
          updatePriorities: gbdata.updatePriorities,
        },
      });
      if (response.status === 200) {
        return {
          updatePriorities: response.data ? response.data : [],
          errorNo: response.data
            ? response.data.errorNo
              ? response.data.errorNo
              : 0
            : 0,
          errorMessage: response.data
            ? response.data.errorMessage
              ? response.data.errorMessage
              : ""
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateJPMarketOptionPriorities = createAsyncThunk(
  "gb-catogories/updateJPMarketOptionPriorities",
  async (gbdata, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateGBOptionPriorities`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          lastUpdatedBy: gbdata.lastUpdatedBy,
          multiCountryRegionCode: gbdata.multiCountryRegionCode, 
          marketId:gbdata.marketId,       
          fixtureId:gbdata.fixtureId,
          priorityRange:gbdata.priorityRange,
          updatePriorities: gbdata.updatePriorities,
        },
      });
      if (response.status === 200) {
        return {
          updateOptionsPriorities: response.data ? response.data : [],
          errorNo: response.data
            ? response.data.errorNo
              ? response.data.errorNo
              : 0
            : 0,
          errorMessage: response.data
            ? response.data.errorMessage
              ? response.data.errorMessage
              : ""
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateCompititionPriorities = createAsyncThunk(
  "gb-catogories/updateCompititionPriorities",
  async (gbdata, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/UpdateCompetitionsPriorities`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          lastUpdatedBy: gbdata.lastUpdatedBy,
          multiCountryRegionCode: gbdata.multiCountryRegionCode, 
          sportId:gbdata.sportId,
          priorityRange:gbdata.priorityRange,
          updatePriorities: gbdata.updatePriorities,
        },
      });
      if (response.status === 200) {
        return {
          updatedCompititionPriorities: response.data ? response.data : [],
          errorNo: response.data
            ? response.data.errorNo
              ? response.data.errorNo
              : 0
            : 0,
          errorMessage: response.data
            ? response.data.errorMessage
              ? response.data.errorMessage
              : ""
            : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const clientValidationforBonusBet = createAsyncThunk(
  "clients/clientValidationforBonusBet",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/bonus/validate-clients`,
        headers: { Authorization: "Bearer " + getToken() },
        data:  {
          ClientIds: data.ClientIds,
          currencyCode:data.currencyCode
        }    
      });
      if (response.status === 200) {
        return { 
          validateClientResult: response.data ? response.data.result : [], 
          csvfile: response.data ? response.data.csvfile : [],       
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.message? response.data.message:"") : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchClientTransactions = createAsyncThunk(
  "clients/fetchClientTransactions",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/client/transaction/${data.clientId}`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          DateFrom: data.startDate,
          DateTo: data.endDate,
          TransactionTypeCodes: data.TransactionTypeCodes,
          AccountType: data.AccountType,
          pageSize:  data.pageSize,
          pageNo:  data.pageNo,
          searchTerm: data.searchTerm
        },        
      });
      if (response.status === 200) {
        return {
          items: response.data ? response.data.bets : [],
          count: response.data ? response.data.count : 0,
          pageNo: response.data ? response.data.pageNo : 0,
          totalItems: response.data ? response.data.totalItems : 0,
          totalPages: response.data ? response.data.totalPages : 0,
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.message : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTippingDataForResulting = createAsyncThunk(
  "tipping/getTippingDataForResulting",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetTippingDataForResulting`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: data.multiCountryRegionCode,
          limit: data.limit,
          offset: data.offset,
          tab: data.tab,
          roundId:data.roundId,
          fixtureId: data.fixtureId,
          competitionId: data.competitionId,
          sportId: data.sportId
        },        
      });
      if (response.status === 200) {
        return {
          TippingResultingData: response.data ? response.data.TippingResultingData : [],
          count: response.data ? response.data.count : 0,
          pageNo: response.data ? response.data.currentPage : 0,
          totalItems: response.data ? response.data.itemsCount : 0,
          totalPages: response.data ? response.data.numberOfPages : 0,
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
          limit: response.data.limit,
          offset: response.data.offset,
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resultTippingBets = createAsyncThunk(
  "tipping/resultTippingBets",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/ResultTippingBet`,
        headers: { Authorization: "Bearer " + getToken() },
        data: data.confirmResults,        
      });
      if (response.status === 200) {
        return {
          TippingResultingData: response.data ? response.data : [],          
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllSports = createAsyncThunk(
  "tipping/getAllSports",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetAllSports`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          parentCategoryIds: data.parentCategoryIds,
          multiCountryRegionCode: data.multiCountryRegionCode,
        },
      });
      if (response.status === 200) {
        return {
          sports: response.data ? response.data : [],          
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.errorMessage? response.data.errorMessage:"") : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllCompetitions = createAsyncThunk(
  "tipping/getAllCompetitions",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetAllCompetitions`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          competitionIds: data.competitionIds,
          multiCountryRegionCode: data.multiCountryRegionCode,
        },
      });
      if (response.status === 200) {
        return {
          Competitions: response.data ? response.data : [],          
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.errorMessage? response.data.errorMessage:"") : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllSeasons = createAsyncThunk(
  "tipping/getAllSeasons",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/AllSeason`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          leagueIds: data.leagueIds,
          multiCountryRegionCode: data.multiCountryRegionCode,
        },
      });
      if (response.status === 200) {
        return {
          seasons: response.data ? response.data : [],          
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.errorMessage? response.data.errorMessage:"") : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllFixtures = createAsyncThunk(
  "tipping/getAllFixtures",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/AllEvents`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          seasonId: data.seasonId,
          multiCountryRegionCode: data.multiCountryRegionCode,
        },
      });
      if (response.status === 200) {
        return {
          fixtures: response.data ? response.data : [],          
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.errorMessage? response.data.errorMessage:"") : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTippingResultingRounds = createAsyncThunk(
  "tipping/getTippingResultingRounds",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/TippingResultRounds`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          multiCountryRegionCode: data.multiCountryRegionCode,
          competitionId: data.competitionId,
          limit: data.limit,
          searchTerm: data.searchTerm,
          offset: data.offset,
          sorting: data.sorting,
          tab: data.tab,
        },        
      });
      if (response.status === 200) {
        return {
          resultingRounds: response.data ? response.data.TippingResultingData : [],          
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Create JackPot Bet
export const createJackPotBet = createAsyncThunk(
  "gb-catogories/CreateJackpotBet",
  async (params, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CreateJackpotBet`,
        headers: { Authorization: "Bearer " + getToken() },
        data:params,
      });
      if (response.status === 200) {
        return {
          items: response.data ? response.data: [],          
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Create Tipping Bet
export const createTippingBet = createAsyncThunk(
  "tipping/createTippingBet",
  async (tippingData, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CreateTippingBet`,
        headers: { Authorization: "Bearer " + getToken() },
        data: tippingData,
      });
      if (response.status === 200) {
        return {
          items: response.data ? response.data : [],          
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCMSCreateUpdate = createAsyncThunk(
  "cms/getCMSCreateUpdate",
  async (formData, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CMSCreateUpdate`,
        headers: { Authorization: "Bearer " + getToken() },
        data: formData,
      });
      if (response.status === 200) {
        return {
          Id: response.data ? response.data.Id : [],
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.errorMessage? response.data.errorMessage:"") : "",
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCMSStatusUpdate = createAsyncThunk(
  "cms/getCMSStatusUpdate",
  async (data, { getState }) => {
    try {           
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CMSstatusUpdate`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          Id: data.Id,
          inactive: data.inactive,
          multiCountryRegionCode: data.multiCountryRegionCode,
        },
      });      
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCMSFeatures = createAsyncThunk(
  "cms/getCMSFeatures",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CMSFeatures`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {},        
      });
      if (response.status === 200) {
        return {
          BetFeatures: response.data ? response.data.BetFeatures : [],          
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],          
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCMSListData = createAsyncThunk(
  "cms/getCMSListData",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/GetCMSData`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          timeFrame: data.timeFrame,
          multiCountryRegionCode : data.multiCountryRegionCode,
          type: data.type,
          limit: data.limit,
          searchTerm: data.search || "",
          offset: data.offset,
          filterUpdated: {
            from: null,
            to: null,
          },
          sorting: data.sorting,
          tab: data.tab,
        },        
      });
      if (response.status === 200) {
        return {
          cmsData: response.data ? response.data.cmsData : [],      
          numberOfPages: response.data ? response.data.numberOfPages : 1,
          currentPage: response.data ? response.data.currentPage : 1,
          errorNo: response.data ? response.data.errorNo : [],
          errorMessage: response.data ? response.data.errorMessage : [],          
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAppScreens = createAsyncThunk(
  "cms/getAppScreens",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/Flutterpaths`,
        headers: { Authorization: "Bearer " + getToken() },       
      });
      if (response.status === 200) {
        return {
          appScreenLinks: response.data ? response.data.flutterPaths : [],   
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }

    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const getPrioityStatus = createAsyncThunk(
  "cms/getPrioityStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/CMScheckPrioityStatus`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          startTime: data.startTime,
          endTime: data.endTime,
          features: data.features,
          country: data.country,
          parentcategory: data.parentcategory,
          displaydata: data.displaydata,
          multiCountryRegionCode: data.multiCountryRegionCode,
        },        
      });
      if (response.status === 200) {
        return {
          CMSPriority: response.data ? response.data.CMSPriority : [],   
          errorNo: response.data ? (response.data.errorNo? response.data.errorNo:0) :1000,
          errorMessage: response.data ?  (response.data.errorMessage? response.data.errorMessage:"") : "",        
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getClientAdjustment = createAsyncThunk(
  "cms/getClientAdjustment",
  async (data, thunkAPI)=> {
    try {           
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/finance/ClientAdjustment`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          clientId: data.clientId,
          accountTypeId: data.accountTypeId,
          adjustmentAmount: data.adjustmentAmount,
          comments: data.comments,
          currencyCode: data.currencyCode,
          reason: data.reason,
          withdrawable: data.withdrawable,
          refNo: null,
        },
      }); 
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createUpdateRound = createAsyncThunk(
  "gb-catogories/createUpdateRound",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/createUpdateRound`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          roundId: gbdata.roundId,
          roundName: gbdata.roundName,
          multiCountryRegionCode:gbdata.multiCountryRegionCode,
          eventIds: gbdata.eventIds,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getFixturesforRound = createAsyncThunk(
  "gb-catogories/getFixturesforRound",
  async (gbdata, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${IRIS_SERV_URL}/getFixturesforRound`,
        headers: { Authorization: "Bearer " + getToken() },
        data: {
          roundId: gbdata.roundId,         
          multiCountryRegionCode:gbdata.multiCountryRegionCode        
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

