import { handleResponse } from "./redux/actions/_helpers/handle-response";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import browserHistory from "./history";
import { toastr } from "react-redux-toastr";
import moment from "moment";

export const apiUrl = process.env.REACT_APP_API_URI.trim();
export const imageBase = process.env.REACT_APP_IMAGE_BASE.trim();
export const clientUrl = process.env.REACT_APP_CLIENT_URI.trim();
export const applicationInsightsKey = process.env.REACT_APP_AI_KEY.trim();
export const envName = process.env.REACT_APP_EVN_NAME.trim();
export const irisnotificationUrl =
  process.env.REACT_APP_IRISNOTIFICATION_URI.trim();
export const apiIRISUrl = process.env.REACT_APP_IRIS_SERV_URL.trim();

// For AU only
export const getClientIdForBetCreation = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return 26515;
    case "azuredev":
      return 26515;
    case "qa":
      return 26515;
    case "uat":
       return 31164;  
      // #31164 Vedavyas RK
    case "production":
      return 14211; 
      //Melissa Baster (#14211)   
    default:
      return " ";
  }
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: applicationInsightsKey,
    disableFetchTracking: false,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

export const applicationInsights = { reactPlugin, appInsights };

export const getToken = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.jwt) {
    return user.jwt;
  } else {
    return "";
  }
};

export const updateArray = (arr, item) => {
  var index = arr.indexOf(item);
  return [
    // part of the array before the given item
    ...arr.slice(0, index),
    // part of the array after the given item
    ...arr.slice(index + 1),
  ];
};

export const fetchPostFormData = async (url, formData) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: { Authorization: "Bearer " + getToken() },
      method: "POST",
      body: formData,
    });
    response = await handleResponse(fetchTask);
    // response = await fetchTask.json();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchPost = async (url, body, hideAuthError = false) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    response = await handleResponse(fetchTask, hideAuthError);
    // response = await fetchTask.json();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchPostIRIS = async (url, body, hideAuthError = false) => {
  url = irisnotificationUrl;
  let response, error;
  try {
    const fetchTask = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    response = await handleResponse(fetchTask, hideAuthError);
    // response = await fetchTask.json();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchPut = async (url, body) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "PUT",
      body: JSON.stringify(body),
    });
    response = await handleResponse(fetchTask);
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchDelete = async (url, body) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "DELETE",
      body: JSON.stringify(body),
    });
    response = await handleResponse(fetchTask);
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchPatch = async (url, body) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "PATCH",
      body: JSON.stringify(body),
    });
    response = await handleResponse(fetchTask);
    // response = await fetchTask.json();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const prepareFetchGet = (url) =>
  fetch(apiUrl + url, {
    headers: { Authorization: "Bearer " + getToken() },
    mode: "cors",
  }).then((r) => r.json());

export const fetchGet = async (url, hideAuthError = false) => {
  let response, error;
  try {
    const fetchTask = await fetch(apiUrl + url, {
      headers: { Authorization: "Bearer " + getToken() },
      mode: "cors",
    });
    // response = await fetchTask.json();
    response = await handleResponse(fetchTask, hideAuthError);
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};


export const downloadCsvFile = async (
  url,
  fileName,
  body,
  callback = null
) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "POST",
      body: JSON.stringify(body),
    });
        
    if (!response.ok) {
      toastr.error(response.statusText);
      return;
    }
    const data = await response.json();
    const blob = new Blob([data.result.content], { type: 'text/json' })
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(blob)
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (!!callback) callback();
  } catch (e) {
    toastr.error("Failed to download file");
  }
};

export const downloadPdfFile = async (
  url,
  fileName,
  body,
  callback = null
) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }

  try {
    fetch(url, {
      headers: {
        "Content-Type": "application/blob",
        Authorization: "Bearer " + getToken(),
      },
      method: "POST",      
      body: JSON.stringify(body),
    })
      .then((response) => response.blob())
      .then((blob) => {       
        const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;        
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        if (!!callback) callback();
      });
  } catch (e) {
    toastr.error("Failed to download file");
  }
};

export const downloadCsv = async (url, fileName, callback) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }
  try {
    const response = await fetch(apiUrl + url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });

    if (response.status === 400) {
      const data = await response.json();
      toastr.error(data.message);
      return;
    }

    if (!response.ok) {
      toastr.error(response.statusText);
      return;
    }
    const data = await response.blob();
    const newUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = newUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (!!callback) callback();
  } catch (e) {
    toastr.error(e);
  }
};

export const downloadCsvPost = async (url, fileName, body, callback = null) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }

  try {
    const response = await fetch(apiUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      toastr.error(response.statusText);
      return;
    }
    const data = await response.blob();
    const newUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = newUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (!!callback) callback();
  } catch (e) {
    toastr.error("Failed to download file");
  }
};

export const downloadselfExclusionCsv = async (
  url,
  fileName,
  body,
  callback = null
) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      toastr.error(response.statusText);
      return;
    }
    const data = await response.blob();
    const newUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = newUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (!!callback) callback();
  } catch (e) {
    toastr.error("Failed to download file");
  }
};

export const clientStatusText = (clientStatusId) => {
  switch (clientStatusId) {
    case 1:
      return "Active";
    case 2:
      return "Suspended - Too Many Login Attempts";
    case 3:
      return "Suspended - Identity not verified";
    case 4:
      return "Closed";
    case 5:
      return "Self Excluded - Permanently";
    case 6:
      return "Self Excluded - 24 hours";
    case 7:
      return "Self Excluded - 7 days";
    case 8:
      return "Self Excluded - 30 days";
    case 9:
      return "NT Excluded";
    case 10:
      return "Account on Hold";
    case 11:
      return "Self-Excluded";
    case 12:
      return "NTRC Excluded";
    case 13:
      return "NTRC Excluded (Permanent)";
    case 14:
      return "SA Barring Order";
    case 15:
      return "SA Barring Order (Permanent)";
    case 16:
      return "NSER";
    case 17:
      return "NSER (Permanent)";
    default:
      return " ";
  }
};

export const badgeColor = (bet) => {
  switch (bet.betStatus) {
    case "Cancelled":
      return "cancelled";
    case "Open":
    default:
      return "open";
    case "PendingApproval":
      return "pending-approval";
    case "PendingResolution":
      return "pending-resolution";
    case "RejectedApproval":
      return "rejected-approval";
    case "Resulted":
      return "resulted";
    case "ResultFlaggedByWinner":
      return "result-flagged-by-winner";
    case "Taken":
      return "taken";
  }
};

export const betStatusBadgeColor = (bet) => {
  switch (bet) {
    case "Cancelled":
      return "cancelled";
    case "Open":
    default:
      return "open";
    case "PendingApproval":
      return "pending-approval";
    case "PendingResolution":
      return "pending-resolution";
    case "RejectedApproval":
      return "rejected-approval";
    case "Resulted":
      return "resulted";
    case "ResultFlaggedByWinner":
      return "result-flagged-by-winner";
    case "Taken":
      return "taken";
  }
};

export const betStatusText = (betStatus) => {
  switch (betStatus) {
    case "PendingApproval":
      return "Pending Approval";
    case "PendingResolution":
      return "Pending Resolution";
    case "RejectedApproval":
      return "Rejected Approval";
    case "ResultFlaggedByWinner":
      return "Result Flagged By Winner";
    default:
      return betStatus;
  }
};

export function changeArrayIndex(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export const ClientPageTabOptions = {
  Profile: "profile",
  Finance: "finance",
  Account: "account",
  Notes: "notes",
  ResponsibleGambling: "responsibleGambling",
  ChatRules: "chatRules",
  SelfExclusionDetails: "selfExclusionDetails",
  BlockedCompetitions: "blockedCompetitions",
  LogTime: "logTime",
};

/*
 * Copied from https://codesandbox.io/s/6ywrkn3jl3
 * */
export function throttle(callback, interval) {
  let debounceTimeoutId;
  return function (...args) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
  };
}

export const getBetTitle = (sport, league, season, round, event, seasonBet) => {
  const sportParent =
    sport && sport.parentCategory ? `${sport.parentCategory} - ` : "";
  const sportChild = sport && sport.ntSport ? `${sport.ntSport} - ` : "";
  const leagueName =
    league && league.leagueCompetition
      ? `${league.leagueCompetition} - `
      : sport && sport.ntSport
      ? sport.ntSport
      : "";
  const seasonName =
    season && season.seasonName ? `${season.seasonName} - ` : "";
  const seasonBetName =
    seasonBet && seasonBet.seasonBetName ? `${seasonBet.seasonBetName} - ` : "";
  const roundName =
    round && round.roundName ? `${round.roundName}  `  : round && round.roundNumber  ? `- Round ${round.roundNumber}` : "";
  const eventName =
    event && event.metaEventName ? `${event.metaEventName} - ` : "";

  return `${sportParent}${sportChild}${leagueName}${seasonName}${seasonBetName}${roundName}${eventName}`;
};

export const anonymousRoutes = [
  "/pages/login",
  "/forgot-password",
  "/reset-password-complete",
];

export const isAnonymousRoute = (route) => {
  for (let aRoute of anonymousRoutes) {
    if (route.startsWith(aRoute)) return true;
  }
  return false;
};

export const compareChatsForSortByMessageTime = (a, b) => {
  const aTimestamp = a.MessageTime.toDate();
  const bTimestamp = b.MessageTime.toDate();

  if (aTimestamp > bTimestamp) {
    return -1;
  }
  if (aTimestamp < bTimestamp) {
    return 1;
  }
  return 0;
};

export const removeDublicateValues = (values) => {
  const removeDublicatedObj = {};
  const nonDublicatedValues = [];
  values.forEach((element) => {
    removeDublicatedObj[element.key] = element;
  });

  for (const key in removeDublicatedObj) {
    nonDublicatedValues.push(removeDublicatedObj[key]);
  }
  return nonDublicatedValues;
};

export const ChatModerateNewMessageStatus = {
  Default: "Default",
  Featured: "Featured",
  Edited: "Edited",
  RemovedByBot: "RemovedByBot",
  RemovedByAdmin: "RemovedByAdmin",
  UserBanned: "UserBanned",
  Pending: "Pending",
};

export const getNameFromClientObj = (clientObj) => {
  if (!clientObj) return "";
  let firstName = "",
    lastName = "";
  try {
    firstName = clientObj.firstName && clientObj.firstName?clientObj.firstName:"";
  } catch (e) {
    console.log("Error getting firstname", e);
  }
  try {
    lastName = clientObj.lastName && clientObj.lastName?clientObj.lastName:"";
  } catch (e) {
    console.log("Error getting lastName", e);
  }
  return `${firstName} ${lastName}`;
};

export const checkAdminPermission = (moduleId) => {
  try {
    const hasModulePermission = JSON.parse(
      localStorage.getItem("user")
    ).moduleIds.some((m) => m === moduleId);
    return hasModulePermission;
  } catch (err) {
    console.log("Error checking permission", err);
    return false;
  }
};

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

export const CategoriesTabs = {
  Sports: "sports",
  Entertainment: "entertainment",
  Novelty: "novelty",
};

export const TippingResultingTabs = {
  Resulted: "resulted",
  Active: "active",
};

export const TippingActive_Rounds = {
  Active_Rounds_Resulting: "round-resulting",
  Resulted_Rounds_Resulting: "round-resulting",
  Resulting_Status_Active: "Active",
  Resulting_Status_Result: "ReadyToResult",
};

export const GBResultingTabs = {
  Reverse_Market: "reverse-market",
  Ready_To_Result_Markets: "ready-to-result-markets",
  Active_Markets: "active-markets",  
};

export const GBResultingActive_Markets = {
  Active_Markets_Resulting: "markets-resulting",
  Resulting_Status_Active: "Active",
  Resulting_Status_Result: "ReadyToResult",
  Resulting_Status_Reverse: "ReadyToReverse",
};

export const MarketCreationTabs = {
  Sports: "sports",
  Entertainment: "entertainment",
  Novelty: "novelty",
};

export const TippingCreationTabs = {
  Sports: "sports",
};

export const GBItemType = {
  Sport: "sport",
  Competition: "competition",
  Fixture: "fixture",
  Market: "market",
};

export const TippingItemType = {
  Sport: "sport",
  Competition: "competition",
  Season: "season",
  SeasonBet: "seasonbet",
  Fixture: "fixture",
  Round: "round",
  TippingFixture: "tippingFixture",
  Market: "market",
  MarketOptions: "MarketOptions",
};

export const ConsoleGamingTabs = {
  Active: "Console Gaming",
  // Archive: "Archive"
};
export const ConsoleGamingTabsConstants = [
 { categoryType: "Console Gaming"},
//  { categoryType: "Archive"}
];

export const  MarketCreationTabConstants = [
  { categoryType: "Sports" },
  { categoryType: "Entertainment" },
  { categoryType: "Novelty" },
];

const ar = [{ link: "apple" }, { link: "google" }, { link: "shopify" }];
export const CMSCategoriesTabs = {
  Sports: "sports",
  Entertainment: "entertainment",
  Novelty: "novelty",
  People: "people",
};

export const CMS_CATEGORY_TAB = {
  PROMO_TILE: "Promo tile",
  FEATURED_EVENT: "Featured Events",
};

export const CMSContentTabs = {
  promoTile: "Promo tile",
  featureEvents: "Featured Events",
};

export const CMSCategoriesSubTabs = {
  Past: "Past",
  Live: "Live",
  Future: "Future",
};
export const BetRejectionSubTabs = {
  Rejections: "Rejections",
  Cancelation: "Cancellation",
};

export const BetReasonsType = {
  Rejections: "7",
  Cancelation: "8",
};
export const MasterCategoriesTabs = {
  Sports: "sports",
  Entertainment: "entertainment",
  Novelty: "novelty",
};

export const CompetitionLeagueTabs = {
  International: "international",
  MinorLeague: "minorLeague",
};

export const MarketFixtureTabs = {
  Markets: "markets",
  Fixtures: "fixtures",
};

export const RoundFixtureTabs = {
  Rounds: "rounds",
  Fixtures: "fixtures",
};

export const TeamLeagueTab = {
  International: "international",
  MinorLeague: "minorLeague",
};

export const CategoriesListType = {
  Category: "category",
  Competition: "competition",
  Season: "season",
  SeasonBet: "fixture",
};

export const ItemType = {
  Sport: "sport",
  Competition: "competition",
  Season: "season",
  SeasonBet: "seasonbet",
  Fixture: "fixture",
  Round: "round",
  TippingFixture: "tippingFixture",
  Market: "market",
  MarketOptions: "MarketOptions",
};

export const ItemList = {
  catogories: "catogoriesList",
  Competition: "compititionList",
  fixtures: "fixturesList",
  rounds: "roundList",
  tippingFixture: "tippingFixtureList",
  markets: "marketListForFixture",
  marketList: "marketList",
  marketOptions: "marketOptionsList",  
  systemConfigurations: "systemConfigurations",
  searchedTeamList:"searchedTeamList",
  consoleGaming: "consoleGamingList",
  validateClientResponse:"validateClientResponse"
};

export const sortBySportOptions = [
  { key: "Priority", value: "Priority" },
  { key: "Inactive", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "-CreatedUtc", value: "Most recent" },
];

export const GBsortByOptions = [
  { key: "Priority", value: "Priority" },
  { key: "Name", value: "Alphabetical" },
  { key: "-UpdatedUtc", value: "Most recent" },
];

export const GBCatogorySortByOptions = [
  { key: "Priority", value: "Priority" },
  { key: "Name", value: "Alphabetical" },
  { key: "UpdatedUtc", value: "Most recent" },
];

export const GBsortByFixtureOptions = [
  { key: "StartTimeUtc", value: "Start Date Time" },
  // { key: "UpdatedUtc", value: "Most recent created" },
  // { key: "Team1", value: "Alphabetical Team 1" },
  // { key: "Team2", value: "Alphabetical Team 2" },
  { key: "Venue", value: "Alphabetical Venue" },
  // { key: "EventName", value: "Alphabetical Event Name" },
  { key: "RoundName", value: "Alphabetical Round Name" },
];

export const TippingsortByOptions = [
  { key: "Priority", value: "Priority" },
  { key: "Name", value: "Alphabetical" },
  { key: "-UpdatedUtc", value: "Most recent" },
];

export const TippingsortByFixtureOptions = [
  { key: "tippingEnabled", value: "Status" },
  { key: "RoundName", value: "Alphabetical" },
  { key: "StartTimeUtc", value: "Start Date Time" },
  { key: "EndTimeUtc", value: "EndDateTime" },
];

export const TippingsortByRoundOptions = [
  { key: "RoundName", value: "Alphabetical" },
  { key: "-UpdatedUtc", value: "Most recent" },
  { key: "StartTimeUtc", value: "StartDateTime" },
  { key: "EndTimeUtc", value: "EndDateTime" },
];

export const gbsortBySportOptions = [
  { key: "Priority", value: "Priority" },
  { key: "IsGroupBetEnabled", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "-CreatedUtc", value: "Most recent" },
];

export const sortByCompetitionOptions = [
  { key: "Priority", value: "Priority" },
  { key: "Inactive", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "-CreatedUtc", value: "Most recent" },
  // { key: "-UpdatedUtc", value: "UpdatedUtc" },
];

export const sortByVenueOptions = [
  // { key: "Priority", value: "Priority" },
  { key: "Status", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "CreatedUtc", value: "Most recent" },
];

export const sortByDivisionOptions = [
  // { key: "Priority", value: "Priority" },
  { key: "-Status", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "CreatedUtc", value: "Most recent" },
];

export const sortByTeamList = [
  // { key: "Priority", value: "Priority" },
  { key: "Status", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "-updatedOn", value: "Most recent" },
];

export const sortByCompitionTeamList = [
  
  { key: "Name", value: "Alphabetical" },
  { key: "-updatedOn", value: "Most recent" },
];


export const sortByParentCategoryOption = [
  { key: "Priority", value: "Priority" },
  { key: "Inactive", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "-CreatedUtc", value: "Most recent" },
];

export const sortByFixtureOptions = [
  { key: "RoundName", value: "Round Name" },
  { key: "Inactive", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "StartTimeUtc", value: "Start Date Time" },
  { key: "-CreatedUtc", value: "Most recent created" },
];

export const gbsortByMarketOptions = [
  { key: "IsEnabled", value: "Status" },
  { key: "Priority", value: "Priority" },
  { key: "Name", value: "Alphabetical" },
  { key: "UpdatedDate", value: "Most recent" },
];

export const gbsortByMarket = [
  { key: "IsEnabled", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "UpdatedDate", value: "Most recent" },
];

export const sortByMarketOptions = [
  { key: "RoundName", value: "Round Name" },
  { key: "Inactive", value: "Status" },
  { key: "Name", value: "Alphabetical" },
  { key: "StartTimeUtc", value: "Start Date Time" },
  { key: "-CreatedUtc", value: "Most recent created" },
];

export const sortByTeams = [
  { key: "isMappedWithDivision", value: "isMappedWithDivision" },
  { key: "-isMappedWithDivision", value: "-isMappedWithDivision" },
  { key: "isMappedWithCompetition", value: "isMappedWithCompetition" },
  { key: "-isMappedWithCompetition", value: "-isMappedWithCompetition" },
];

export const selfExclusionTypesforEndDates = {
  SelfExcluded: "Self-Excluded",
  NTRCExcluded: "NTRC Excluded",
  SABarringOrder: "SA Barring Order",
  NSER: "NSER",
  TakeBreak: "Take a Break"
};

export const sortByConsoleGaming = [
  { key: "-ShowLiveTag", value: "Show Live" },
  { key: "BetId", value: "Bet Id" },
  { key: "ConsoleGame", value: "Game" },
  { key: "Name", value: "Bet Type" },
  {key: "scheduledDateTime", value: "Scheduled Date Time"}
];

export const ItemLimitsInPage = [10, 20, 50, 100];

export const computeReduxKey = (tab, listType) => `${tab}-${listType}`;

export const generateReduxKey = ({
  itemType,
  mainTab,
  leagueTab,
  itemSeasonBetType,
}) =>
  `${mainTab}-${itemType}${leagueTab ? "-" + leagueTab : ""}${
    itemSeasonBetType ? "-" + itemSeasonBetType : ""
  }`;

/*
 * Copied from https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text
 * */
export const variableToSentence = (varName) => {
  const result = varName.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isNotNullandUndefined = (value) => {
  if (value !== undefined && value !== null) {
    return true;
  } else {
    return false;
  }
};

export const isNotNullEmptyandUndefined = (value) => {
  if (value !== undefined && value !== null && value !== "") {
    return true;
  } else {
    return false;
  }
};

export const isNotUndefined = (value) => {
  if (value !== undefined) {
    return true;
  } else {
    return false;
  }
};

export const isEmptyNotNullandUndefined = (value) => {
  if (value !== undefined && value !== null && value === "") {
    return true;
  } else {
    return false;
  }
};

export const isEmptyOrNull = (value) => {
  if (value === null || value === "") {
    return true;
  } else {
    return false;
  }
};

export const isNotNull = (value) => {
  if (value !== null ) {
    return true;
  } else {
    return false;
  }
};

export const variableReplacement = (varName) => {
  const result = varName.replace(/-/g, " ");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const titleCase = (str) => {
  if (!str) return str;
  return (
    str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase()
  );
};

export const listOfCountries = () => {};

export const sortByText = (items, variableName, isAsc) => {
  if (!items || items.length === 0) return items;
  return items.sort((a, b) => {
    const valA = a.name
      ? a.name.toUpperCase()
      : a.externalName
      ? a.externalName.toUpperCase()
      : "";
    const valB = b.name
      ? b.name.toUpperCase()
      : b.externalName
      ? b.externalName.toUpperCase()
      : "";
    if (valA < valB) return isAsc ? -1 : 1;
    if (valA > valB) return isAsc ? 1 : -1;
    return 0;
  });
};

export const computeRoute = ({
  itemType,
  sportId,
  competitionId,
  seasonId,
  fixtureId,
  roundId,
  mainTab,
  leagueTab,
}) => {
  itemType = itemType ? itemType.toLowerCase() : "";
  if (itemType === ItemType.Sport) {
    return `/categories/${mainTab}`;
  }
  if (itemType === ItemType.Competition) {
    return `/competitions/${mainTab}/${sportId}/${leagueTab}`;
  }
  if (itemType === ItemType.Season) {
    return `/fixtures/${mainTab}/${seasonId}/${leagueTab}`;
  }
};

export const defaultSource = "BuddyBet";

export const bgModelMessageHandShakeCompetitionActiveAll =
  "By Selecting it as Yes, this sport's competition, seasons and fixture will have only Handshake bet.";
export const bgModelMessageHandShakeActiveAll =
  "Once you select this, all the bets for selected competition & its seasons and fixtures will be converted as Handshake bet into application from now onwards. Are you sure want to proceed?";

export const bgModelMessageStatusInActiveAll =
  "Once you make this competition Inactive, all its seasons and fixture will set as Inactive and it won't  appear into the mobile application . Are you sure want to proceed";

export const bgModalMessageActiveAll =
  "Once you make this category or categories Active, it will start appearing in the mobile application. Are you sure want to proceed?";

export const gbStatusChangeModalMessage = "Are you sure want to proceed?";

export const gbStatusVoidChangeModalMessage ="You have selected Result and Void option. Are you sure want to proceed?";
export const proceedModalMessage ="Are you sure want to proceed?";
export const statusConfirmation ="You are about to change status. Are you sure you want to continue?";
export const bgModalMessageInactiveAll =  "Are you sure want to proceed?";
export const bgModalMessageChangeHandshakeToTrue =
  "Once you select this, all bets for the selected category and it's sub-categories will be converted to Handshake bets in the mobile application from now on. Are you sure want to proceed?";
export const bgModalMessageChangeHandshakeToFalse =
  bgModalMessageChangeHandshakeToTrue;

export const bgModalFixtureChangeStatusToTrue =
  "Once you make this fixture as Active, then it will start appearing in the mobile application. Are you sure want to proceed?";
export const bgModalFixtureChangeStatusToFalse =
  "Once you make this fixture as Inactive, then it won't appear into the mobile application. Are you sure want to proceed?";
export const bgModalMarketChangeStatusToTrue =
  "Once you make this Market as Active, then it will start appearing in the mobile application. Are you sure want to proceed?";
export const bgModalMarketChangeStatusToFalse =
  "Once you make this Market as Inactive, then it won't appear into the mobile application. Are you sure want to proceed?";
export const bgModalFixtureActiveAll =
  "Once you make this fixture or fixtures as Active, it will start appearing in the mobile application. Are you sure want to proceed?";
export const bgModalFixtureInactiveAll =
  "Once you make this fixture or fixtures as Inactive, then it won't appear into the mobile application. Are you sure want to proceed?";
export const bgModalFixtureChangeHandshakeToTrue =
  "By Selecting it as Yes, this fixture will have only Handshake bet.";
export const bgModalFixtureChangeHandshakeToFalse =
  bgModalFixtureChangeHandshakeToTrue;

export const CreateCMSTabChangeRequest =
  "If you change the selection of tab, then your current content will be discarded. Are you sure want to proceed?";

// season bet
export const bgModalSeasonBetChangeStatusToTrue =
  "Once you make this season bet as Active, then it will start appearing in the mobile application. Are you sure want to proceed?";
export const bgModalSeasonBetChangeStatusToFalse =
  "Once you make this season bet as Inactive, then it won't appear into the mobile application. Are you sure want to proceed?";
export const bgModalSeasonBetChangeHandshakeToTrue =
  "By Selecting it as Yes, this season bet will have only Handshake bet.";
export const bgModalSeasonBetChangeHandshakeToFalse =
  bgModalSeasonBetChangeHandshakeToTrue;

export const bgModalSeasonBetActiveAll =
  "Once you make this season bet or bets as Active, it will start appearing in the mobile application. Are you sure want to proceed?";
export const bgModalSeasonBetInactiveAll =
  "Once you make this season bet or bets as Inactive, then it won't appear into the mobile application. Are you sure want to proceed?";

export const RoundCreationMessage = "Are you sure want to proceed?";

//ClientAccountTab
export const clientRating = "Rating should be between 1-5. Use the +/- to reduce and increase rating.";
export const clientNotes = "Remember to add notes.";
export const clientForgetPassword = "Initiate user forgot password using the button below.";
export const clientValidationError= "Rating should be between 1-5";
export const clientErrornote= "Please add rating between 1-5";

export const downloadPdffromBase64 = (pdf, clientDetail) => {
  const { clientid, date } = clientDetail;
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  var downloadDate = moment(date, "MM/YYYY").format("MMM_YYYY");
  const fileName = `activity_statement_${clientid}_${downloadDate}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const displayNotes = (riskName) => {
  switch (riskName) {
    case "Medium":
    case "High":
      return true;
    default:
      return false;
  }
};

export const adjustmentStatusValues = {
  1: "Requested",
  2: "Approved",
  3: "NotApproved",
  4: "Processed",
};

export const WithdrawalStatus = {
  1: "Requested",
  2: "Approved",
  3: "NotApproved",
  4: "RejectedTransactionFailed",
  5: "ApprovedManuallyProcessed",
  6: "Cancelled",
};

export const TippingColorCodes = { 
  0: "tipping-noresult",
  1: "tipping-win",
  2: "tipping-lose",  
  3: "tipping-draw",  
};

export const FinancialProcessStatus = {
  0: "Pending",
  1: "Declined",
  2: "Approved",
  3: "FinalizedPending",
  4: "BatchFileCreatedAndFinalized", 
};

export const TeamsTabUsers = {
  ActiveUsers: "ActiveUsers",
  InActiveUsers: "InactiveUsers",
};



