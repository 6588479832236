import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/firestore";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
});

// const messaging = initializedFirebaseApp.messaging();
// messaging.usePublicVapidKey(
//   // Project Settings => Cloud Messaging => Web Push certificates
//   "BHkV_lnMwGhYjRHhcQDCZLhwKXtBQ6BvxmoO1uB5UjGf2K3G6fEhabLpKYhQZk4vp1H_OtP4Mg_L3Ig9MjxeRSc"
// );

let messaging =null;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    "BHkV_lnMwGhYjRHhcQDCZLhwKXtBQ6BvxmoO1uB5UjGf2K3G6fEhabLpKYhQZk4vp1H_OtP4Mg_L3Ig9MjxeRSc"
  );
} else {
  console.log('no-support :(')
}

const chatsDb = initializedFirebaseApp.firestore();

export { messaging, chatsDb };

