import {  CMS_CATEGORY_TAB, } from "../../../commons";
import { CMSConstants } from "../../actions/cms/constants";

const initState = {
  isLoading : false,
  cmsUpload: null,
  cmsContentList: null,
  cmsCategoryList: null,
  cmsPriorityStatus: [],
  cmsInfluencersList: null,
  cmsListSelectedCountry: "",
  cmsDisplayonScreenList: null,
  cmsCompetitionSeasonsList: [],
  cmsCategoryCompetitionList: [],
  cmsCategoryTab: CMS_CATEGORY_TAB.PROMO_TILE,
  cmsSeasonsAndFixturesBets:{events : [],seasonBets : []},
};

export const cmsReducer = (state = initState, action) => {
  switch (action.type) {
    case "CMS_CONTENT_LIST":
      return {
        ...state,
        cmsContentList: action.payload,
      };
    case "GET_CATEGORY_COMPETITION_LIST":
      return {
        ...state,
        cmsCategoryCompetitionList: action.payload,
      };
    case "CLEAR_CATEGORY_COMPETITION_LIST":
      return {
        ...state,
        cmsCategoryCompetitionList: [],
      };
    case "GET_CMS_CATEGORY_LIST":
      return {
        ...state,
        cmsCategoryList: action.payload,
      };
    case "GET_CMS_INFLUENCERS_LIST":
      return {
        ...state,
        cmsInfluencersList: action.payload,
      };
    case "GET_CMS_DISPLAYONSCREEN_LIST":
      return {
        ...state,
        cmsDisplayonScreenList: action.payload,
      };
    case "CMS_UPLOAD_SUCCESS":
      return {
        ...state,
        cmsUpload: action.payload,
      };
    case "UPDATE_CMS_STATUS_REQUEST":
      return {
        ...state,
        cmsContentList: { ...state.cmsContentList, items: action.payload },
      };
    case "CMS_PRIORITY_STATUS":
      return {
        ...state,
        cmsPriorityStatus: action.payload,
      };
      case "SET_SELECTED_COUNTRY":
      return {
        ...state,
        cmsListSelectedCountry: action.payload,
      };
    case CMSConstants.GET_COMPETITION_SEASON_LIST:
      return {
      ...state,
      cmsCompetitionSeasonsList : action.payload
    }
    case CMSConstants.GET_SEASON_BATS_AND_FIXTURES:
      return {
      ...state,
      cmsSeasonsAndFixturesBets : action.payload
      }
      case CMSConstants.GET_SEASON_BATS_AND_FIXTURES:
        return {
        ...state,
        cmsSeasonsAndFixturesBets : action.payload
      }
      case CMSConstants.SET_CMS_CATEGORY_TAB:
        return {
          ...state,
          cmsCategoryTab: action.payload,
        };
    case CMSConstants.LOAD_CMS: 
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};
