// import external modules
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux";
import "moment-timezone";
// import internal(own) modules
//import registerServiceWorker from "./registerServiceWorker";
import { store } from "./redux/storeConfig/store";
import ReduxToastr from "react-redux-toastr";
import "semantic-ui-css/semantic.min.css";
import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./components/spinner/spinner";

import { applicationInsights } from "./commons";
import ModalFactory from "react-modal-promise";
import { QueryClient, QueryClientProvider } from "react-query";
import { APIInterceptors } from "./helpers/APIClient";

applicationInsights.appInsights.loadAppInsights();
applicationInsights.appInsights.trackPageView();

const LazyApp = lazy(() => import("./app/app"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}
const queryClient = new QueryClient();
APIInterceptors();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <QueryClientProvider client={queryClient}>
        <LazyApp />
      </QueryClientProvider>

      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-left"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <ModalFactory />
    </Suspense>
  </Provider>
);
