
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchclientStatus,
  fetchClientDetailsbyClientId,
  createUpdateClient,
  fetchAdminClientSelfExclusion,
  UpdateExclusionSponsorDetail,
  fetchStateList,
  sendIntialEmailToSponsor,
  downloadSelfExclusionCSV,
  fetchAdminClientActivity,  
  fetchPepList,
  initiateManualwithdrawals,
  rewardBonustoClients,
  clientValidationforBonusBet,
  fetchClientTransactions,
  getClientAdjustment,
} from "../../../utility/thunks";

const initialState = {
  loading: true,
  currentUserDetail: {
    loading: true,
    items: [],
    errorNo: 0,
    errorMessage: "",
  },
  clientSelfExclusion: {
    loading: true,
    items: [],
    errorNo: 0,
    errorMessage: "",
  },  
  sponsorDetails: {
    loading: true,
    items: [],
    errorNo: 0,
    errorMessage: "",
  },
  clientStatusList: {
    loading: true,
    selectedItemIds: [],
    updatingStatusIds: [],
    items: [],
    errorNo: 0,
    errorMessage: "",
    searchTerm: "",
  },
  stateList: {
    items: [],
    errorNo: 0,
    errorMessage: "",
  },
  risksList: {
    items: [],
    errorNo: 0,
    errorMessage: "",
  },
  sendEmail: {
    items: [],
    errorNo: 0,
    errorMessage: "",
  },
  csvDownload: {
    responseData: null,
    errorNo: 0,
    errorMessage: "",
  },
  clientActivityLogs: {
    loading: true,
    items: [],
    count: 0,
    pageNo: 0,
    totalItems: 0,
    totalPages: 0,
    errorNo: 0,
    errorMessage: "",
  },
  manualWithdrawal: {
    items: [],
    errorNo: 0,
    errorMessage: "",
  },
  rewardbonusToClients: {
    rewardbonusResponse: [],
    errorNo: 0,
    errorMessage: "",
    loading: false,
  },
  validateClientResponse: {
    selectedClientIds: [],
    validateClientResult: [],
    csvfile: [],
    errorNo: 0,
    errorMessage: "",
    loading: false,

  },
  clientTransactions: {
    loading: true,
    items: [],
    count: 0,
    pageNo: 0,
    totalItems: 0,
    totalPages: 0,
    errorNo: 0,
    errorMessage: "",
  },
  clientAdjustment:{
    loading: false,
    data: null,
    errorNo: 0,
    errorMessage: "",
  },
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setClientStatusFilters: (state, action) => {
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;
    },
    updateSelectedClientIds: (state, action) => {
      state[action.payload.ItemListName].selectedClientIds =
        action.payload.selectedClientIds;
    },    
    resetClientAdjustment(state) {
      state.loading = false;
    },    
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchclientStatus.pending, (state) => {
        state.clientStatusList.loading = true;
      })
      .addCase(fetchclientStatus.fulfilled, (state, action) => {
        state.clientStatusList.loading = false;
        state.clientStatusList = action.payload;
      })
      .addCase(fetchclientStatus.rejected, (state) => {
        state.clientStatusList.loading = false;
      })
      .addCase(fetchClientDetailsbyClientId.pending, (state) => {
        state.currentUserDetail.loading = true;
      })
      .addCase(fetchClientDetailsbyClientId.fulfilled, (state, action) => {
        state.currentUserDetail.loading = false;
        state.currentUserDetail = action.payload;
      })
      .addCase(fetchClientDetailsbyClientId.rejected, (state, action) => {
        state.currentUserDetail.loading = false;
        state.currentUserDetail.errorNo = action.payload.errorNo;
        state.currentUserDetail.errorMessage = action.payload.errorMessage;
      })
      .addCase(createUpdateClient.pending, (state) => {
        state.currentUserDetail.loading = true;
      })
      .addCase(createUpdateClient.fulfilled, (state, action) => {
        state.currentUserDetail.loading = false;
        state.currentUserDetail = action.payload;
        state.currentUserDetail.errorNo = action.payload.errorNo;
        state.currentUserDetail.errorMessage = action.payload.errorMessage;
      })
      .addCase(createUpdateClient.rejected, (state, action) => {
        state.currentUserDetail.loading = false;
        state.currentUserDetail.errorNo = action.payload.errorNo;
        state.currentUserDetail.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchAdminClientSelfExclusion.pending, (state) => {
        state.clientSelfExclusion.loading = true;
      })
      .addCase(fetchAdminClientSelfExclusion.fulfilled, (state, action) => {
        state.clientSelfExclusion.loading = false;
        state.clientSelfExclusion = action.payload;
      })
      .addCase(fetchAdminClientSelfExclusion.rejected, (state, action) => {
        state.clientSelfExclusion.loading = false;
        state.clientSelfExclusion.errorNo = action.payload.errorNo;
        state.clientSelfExclusion.errorMessage = action.payload.errorMessage;
      })
      .addCase(UpdateExclusionSponsorDetail.pending, (state) => {
        state.sponsorDetails.loading = true;
      })
      .addCase(UpdateExclusionSponsorDetail.fulfilled, (state, action) => {
        state.sponsorDetails.loading = false;
        state.sponsorDetails = action.payload;
      })
      .addCase(UpdateExclusionSponsorDetail.rejected, (state, action) => {
        state.sponsorDetails.loading = false;
        state.sponsorDetails.errorNo = action.payload.errorNo;
        state.sponsorDetails.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchStateList.pending, (state) => {
        state.stateList.loading = true;
      })
      .addCase(fetchStateList.fulfilled, (state, action) => {
        state.stateList.loading = false;
        state.stateList = action.payload;
      })
      .addCase(fetchStateList.rejected, (state, action) => {
        state.stateList.loading = false;
        state.stateList.errorNo = action.payload.errorNo;
        state.stateList.errorMessage = action.payload.errorMessage;
      })
      .addCase(sendIntialEmailToSponsor.pending, (state) => {
        state.sendEmail.loading = true;
      })
      .addCase(sendIntialEmailToSponsor.fulfilled, (state, action) => {
        state.sendEmail.loading = false;
        state.sendEmail = action.payload;
      })
      .addCase(sendIntialEmailToSponsor.rejected, (state, action) => {
        state.sendEmail.loading = false;
        state.sendEmail.errorNo = action.payload.errorNo;
        state.sendEmail.errorMessage = action.payload.errorMessage;
      })
      .addCase(downloadSelfExclusionCSV.pending, (state) => {
        state.csvDownload.loading = true;
      })
      .addCase(downloadSelfExclusionCSV.fulfilled, (state, action) => {
        state.csvDownload.loading = false;
        state.csvDownload = action.payload;
      })
      .addCase(downloadSelfExclusionCSV.rejected, (state, action) => {
        state.csvDownload.loading = false;
        state.csvDownload.errorNo = action.payload.errorNo;
        state.csvDownload.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchAdminClientActivity.pending, (state) => {
        state.clientActivityLogs.loading = true;
      })
      .addCase(fetchAdminClientActivity.fulfilled, (state, action) => {
        state.clientActivityLogs.loading = false;
        state.clientActivityLogs = action.payload;
      })
      .addCase(fetchAdminClientActivity.rejected, (state, action) => {
        state.clientActivityLogs.loading = false;
        state.clientActivityLogs.errorNo = action.payload.errorNo;
        state.clientActivityLogs.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchPepList.pending, (state) => {
        state.risksList.loading = true;
      })
      .addCase(fetchPepList.fulfilled, (state, action) => {
        state.risksList.loading = false;
        state.risksList = action.payload;
      })
      .addCase(fetchPepList.rejected, (state, action) => {
        state.risksList.loading = false;
        state.risksList.errorNo = action.payload.errorNo;
        state.risksList.errorMessage = action.payload.errorMessage;
      })
      .addCase(initiateManualwithdrawals.pending, (state) => {
        state.manualWithdrawal.loading = true;
      })
      .addCase(initiateManualwithdrawals.fulfilled, (state, action) => {
        state.manualWithdrawal.loading = false;
        state.manualWithdrawal = action.payload;
      })
      .addCase(initiateManualwithdrawals.rejected, (state, action) => {
        state.manualWithdrawal.loading = false;
        state.manualWithdrawal.errorNo = action.payload.errorNo;
        state.manualWithdrawal.errorMessage = action.payload.errorMessage;
      })
      .addCase(rewardBonustoClients.pending, (state) => {
        state.rewardbonusToClients.loading = true;
      })
      .addCase(rewardBonustoClients.fulfilled, (state, action) => {
        state.rewardbonusToClients.loading = false;
        state.rewardbonusToClients = action.payload;
      })
      .addCase(rewardBonustoClients.rejected, (state, action) => {
        state.rewardbonusToClients.loading = false;
        state.rewardbonusToClients.errorNo = action.payload.errorNo;
        state.rewardbonusToClients.errorMessage = action.payload.errorMessage;
      })
      .addCase(getClientAdjustment.pending, (state) => {
        state.clientAdjustment.loading = true;
      })
      .addCase(getClientAdjustment.fulfilled, (state, action) => {
        state.clientAdjustment.loading = false;
        state.clientAdjustment = action.payload;
      })
      .addCase(getClientAdjustment.rejected, (state, action) => {
        state.clientAdjustment.loading = false;
        state.clientAdjustment.errorNo = action.payload.errorNo;
        state.clientAdjustment.errorMessage = action.payload.errorMessage;
      })
      .addCase(clientValidationforBonusBet.pending, (state) => {
        state.validateClientResponse.loading = true;
      })
      .addCase(clientValidationforBonusBet.fulfilled, (state, action) => {
        state.validateClientResponse.loading = false;
        state.validateClientResponse = action.payload;
      })
      .addCase(clientValidationforBonusBet.rejected, (state, action) => {
        state.validateClientResponse.loading = false;
        state.validateClientResponse.errorNo = action.payload.errorNo;
        state.validateClientResponse.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchClientTransactions.pending, (state) => {
        state.clientTransactions.loading = true;
      })
      .addCase(fetchClientTransactions.fulfilled, (state, action) => {
        state.clientTransactions.loading = false;
        state.clientTransactions = action.payload;
      })
      .addCase(fetchClientTransactions.rejected, (state, action) => {
        state.clientTransactions.loading = false;
        state.clientTransactions.errorNo = action.payload.errorNo;
        state.clientTransactions.errorMessage = action.payload.errorMessage;
      });
  },
});

export const { updateSelectedItemIds,updateSelectedClientIds, setClientStatusFilters, resetClientAdjustment } =
  clientsSlice.actions;
export default clientsSlice.reducer;
