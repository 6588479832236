import { ClientPageTabOptions, fetchGet, fetchPost, downloadPdffromBase64 } from "../commons";
import { getClientStatuses } from "./actions/clients/clientsActions";
import { toastr } from "react-redux-toastr";
import moment from "moment";

export const requestClientDetailType = "REQUEST_CLIENT_DETAIL";
export const responseClientDetailType = "RESPONSE_CLIENT_DETAIL";
export const requestUpdateClientDetailType = "REQUEST_UPDATE_CLIENT";
export const requestDeleteClientImage = "REQUEST_DELETE_CLIENT_IMAGE";
export const responseUpdateClientDetailType = "RESPONSE_UPDATE_CLIENT";
export const requestClientActivityFile = "REQUEST_CLIENT_ACTIVITY_FILE";
export const responseClientActivityFile = "RESPONSE_CLIENT_ACTIVITY_FILE";

export const requestSendActivityFile = "REQUEST_CLIENT_SEND_ACTIVITY_FILE";
export const responseSendActivityFile = "RESPONSE_CLIENT_SEND__ACTIVITY_FILE";

export const requestAddRatingType = "REQUEST_ADD_RATING";
export const responseAddRatingType = "RESPONSE_ADD_RATING";
export const requestForgotPassword = "REQUEST_FORGOT_PASSWORD";
export const responseForgotPassword = "RESPONSE_FORGOT_PASSWORD";
export const requestCreateClient = "REQUEST_CREATE_CLIENT";
export const responseCreateClient = "RESPONSE_CREATE_CLIENT";
export const requestResetPassword = "REQUEST_RESET_PASSWORD";
export const responseResetPassword = "RESPONSE_RESET_PASSWORD";

export const updateClientActiveTab = 'UPATE_CLIENT_ACTIVE_TAB';
export const requestClientAccountDetail = "REQUEST_CLIENT_ACCOUNT_DETAIL";
export const responseClientAccountDetail = "RESPONSE_CLIENT_ACCOUNT_DETAIL";

export const requestClientTransactionDetail =
  "REQUEST_CLIENT_TRANSACTION_DETAIL";
export const responseClientTransactionDetail =
  "RESPONSE_CLIENT_TRANSACTION_DETAIL";

export const requestCreatingTransaction = "REQUEST_CREATING_CLIENT_TRANSACTION";
export const requestCreateClientTransaction =
  "REQUEST_CREATE_CLIENT_TRANSACTION";
export const resetError = "RESET_ERROR";

export const requestValidateClientId = "REQUEST_VALIDATE_CLIENTID";
export const responseValidateClientId = "RESPONSE_VALIDATE_CLIENTID";

export const requestClientNotes = "REQUEST_CLIENT_NOTES";
export const responseClientNotes = "RESPONSE_CLIENT_NOTES";

export const responseCreateClientNotes = "RESPONSE_CREATE_CLIENT_NOTES";

export const requestClientResponsibleGambling =
  "REQUEST_CLIENT_RESPONSIBLE_GAMBLING";
export const requestClientSelfExclusion = "REQUEST_CLIENT_SELF_EXCLUSION";
export const responseClientResponsibleGambling =
  "RESPONSE_CLIENT_RESPONSIBLE_GAMBLING";
export const responseClientSelfExclusion = "RESPONSE_CLIENT_SELF_EXCLUSION";
export const requestClientBlockedCompetition = 'REQUEST_CLIENT_BLOCKED_COMPETITION';
export const requestClientBlockedCompetitionDone = 'REQUEST_CLIENT_BLOCKED_COMPETITION_DONE'
export const responseClientBlockedCompetition = 'RESPONSE_CLIENT_BLOCKED_COMPETITION';
export const requestAddClientBlockedCompetition = 'REQUESTED_CLIENT_ADD_BLOCKED_COMPETITON'

export const requestClientChatRules = "REQUEST_CLIENT_CHAT_RULES";
export const responseClientChatRules = "RESPONSE_CLIENT_CHAT_RULES";
export const requestClientChatRulesUpdate = "REQUEST_CLIENT_CHAT_RULES_UPDATE";

const initialState = {
  gettingUserDetail: false,
  currentUserDetail: null,
  updatingClient: false,
  deletingImage: false,
  addingRating: false,
  addingRatingResponse: null,
  requestingForgotPassword: false,
  creatingClient: false,
  createClientResponse: null,
  //transaction details
  currentActiveTab: ClientPageTabOptions.Profile,
  gettingTransactionDetail: false,
  clientAccountDetail: null,
  clientTransactionDetails: null,
  creatingTransaction: false,
  createSuccessMessage: null,
  validatedResult: false,
  //Notes
  clientNotes: null,
  isNotesRequested: false,
  //ResponsibleGambling
  isResponsibleGamblingRequested: false,
  isSelfExclusionRequested: false,
  clientResponsibleGambling: null,
  clientSelfExclusion: null,
  //chat rules
  chatRules: null,
};

export const actionCreators = {
  getUserDetail: (clientId) => async (dispatch, getState) => {
    dispatch({ type: requestClientDetailType });
    dispatch(getClientStatuses());
    const [, response] = await fetchGet("/clients/" + clientId);
    dispatch({ type: responseClientDetailType, response });
  },
  updateClientDetail:
    (clientDetail, action, cb = () => { }) =>
      async (dispatch, getState) => {
        if (action > 0) {
          dispatch({ type: requestUpdateClientDetailType });
        } else {
          dispatch({ type: requestDeleteClientImage });
        }
        const [, response] = await fetchPost("/client", clientDetail);
        if (response && response.errorNo === 5007) toastr.error(response.message);
        if (cb) {
          cb();
        }
        dispatch({ type: responseUpdateClientDetailType, response });
      },
  getClientActivityFile: (clientDetail) => async (dispatch, getState) => {
    dispatch({ type: requestClientActivityFile });

    const [, response] = await fetchPost(
      "/download-activityStatement",
      clientDetail
    );

    if (response) {
      toastr.success("SuccessFully Downloaded");
      downloadPdffromBase64(response,clientDetail);
    } else {
      toastr.error(response ? response.message : "Unknown error");
    }

    dispatch({ type: responseClientActivityFile, response });
  },
  sendClientActivityFile: (clientDetail) => async (dispatch, getState) => {
    dispatch({ type: requestSendActivityFile });

    const [, response] = await fetchPost(
      "/send-activityStatement",
      clientDetail
    );

    if (response && response.errorNo === 0) {
      toastr.success("Activity Statement SuccessFully Sent.!");
    } else {
      toastr.error(response ? response.message : "Unknown error");
    }

    dispatch({ type: responseSendActivityFile, response });
  },
  addRating:
    (clientId, incrementRatingBy, callback = null) =>
      async (dispatch, getState) => {
        dispatch({ type: requestAddRatingType });
        const [, response] = await fetchPost("/clients/rating", {
          clientId,
          incrementRatingBy,
        });
        if (response && response.clientId > 0) toastr.success("Ratings updated");
        dispatch({ type: responseAddRatingType, response });
        if (callback) callback();
      },
  initiateForgotPassword: (clientId) => async (dispatch, getState) => {
    dispatch({ type: requestForgotPassword });
    const [, response] = await fetchPost("/clients/initiate-forgot-pw", {
      clientId,
    });
    if (response && response.errorNo === 0) {
      toastr.success(response.message);
    } else {
      // toastr.error(response ? response.message : 'Unknown error');
    }
    dispatch({ type: responseForgotPassword });
  },
  initiateResetPassword: (clientEmail) => async (dispatch, getState) => {
    dispatch({ type: requestResetPassword });
    const [, response] = await fetchPost("/reset-password", {
      email: clientEmail,
    });
    if (response && response.errorNo === 0) {
      toastr.success(response.message);
    } else {
      toastr.error(response ? response.message : "Unknown error");
    }
    dispatch({ type: responseResetPassword });
  },
  createNewClient: (clientDetails, callback) => async (dispatch, getState) => {
    dispatch({ type: requestCreateClient });
    const [, response] = await fetchPost("/client", clientDetails);
    if (response && response.clientId > 0 && callback)
      callback(response.clientId);
    dispatch({ type: responseCreateClient, response });
  },
  //transactions
  getClientAccountDetail: (clientId) => async (dispatch) => {
    const [, response] = await fetchGet(`/client/${clientId}/account`);
    dispatch({ type: responseClientAccountDetail, response });
  },
  getClientTransactionDetail:
    (clientId, dateFrom, dateTo, accountType, transactionTypeCodes) =>
      async (dispatch) => {
        dispatch({ type: requestClientTransactionDetail });
        const [error, response] = await fetchPost(
          `/client/${clientId}/transaction`,
          {
            DateFrom: dateFrom,
            DateTo: dateTo,
            TransactionTypeCodes: transactionTypeCodes,
            AccountType: accountType,
          }
        );
        dispatch({ type: responseClientTransactionDetail, response });
      },

  createTransaction:
    (
      clientId,
      accountTypeId,
      adjustmentAmount,
      reason,
      currencyCode,
      callback,
      reason1,
      toggle
    ) =>
      async (dispatch) => {
        dispatch({ type: requestCreatingTransaction });
        const [, response] = await fetchPost("/finance/adjustment-insert", {
          ClientId: clientId,
          AccountTypeId: accountTypeId,
          AdjustmentAmount: adjustmentAmount,
          AdjustmentReason: reason,
          CurrencyCode: currencyCode,
          Reason: reason1,
        });

        if (response && response.errorNo === 0 && callback) {
          toggle();
          toastr.success(response && response.message);
          callback();
        } else toastr.error(response && response.message);
        dispatch({ type: requestCreateClientTransaction, response });
      },

  bonusBetClientIdValidation: (clientId) => async (dispatch) => {
    dispatch({ type: requestValidateClientId });
    const [err, response] = await fetchPost(
      "/bonus/validate-clients",
      {
        ClientIds: clientId.filter((i) => i > 0),
      },
      true
    );
    dispatch({ type: responseValidateClientId, response });
  },

  resetError: () => async (dispatch) => {
    dispatch({ type: resetError });
  },

  getClientNotes: (clientId) => async (dispatch) => {
    const [err, response] = await fetchGet(`/client/${clientId}/note`);
    dispatch({ type: responseClientNotes, response });
  },

  createNewClientNotes: (clientId, notes, callback) => async (dispatch) => {
    dispatch({ type: requestClientNotes });
    const [err, response] = await fetchPost(`/client/${clientId}/note`, {
      Note: notes,
    });
    dispatch({ type: responseCreateClientNotes, response });
    if (response && callback) callback();
  },

  getClientResponsibleGambling:
    (
      clientId,
      limitType,
      excludeLimit,
      excludeSelfExclusion,
      dateFrom,
      dateTo
    ) =>
      async (dispatch) => {
        dispatch({ type: requestClientResponsibleGambling });
        const [error, response] = await fetchPost(
          `/client/${clientId}/responsible-gambling`,
          {
            LimitType: limitType,
            ExcludeLimit: excludeLimit,
            ExcludeSelfExclusion: excludeSelfExclusion,
            dateFrom: dateFrom === "" ? null : dateFrom,
            dateTo: dateTo === "" ? null : dateTo,
          }
        );
        dispatch({ type: responseClientResponsibleGambling, response });
      },
  getSelfExclusion:
    (clientId, selfExclusion, dateFrom, dateTo) => async (dispatch) => {
      dispatch({ type: requestClientSelfExclusion });
      const [error, response] = await fetchPost(
        `/client/${clientId}/self-exclusion`,
        {
          excludeSelfExclusion: selfExclusion,
          datefrom: dateFrom === "" ? null : dateFrom,
          dateto: dateTo === "" ? null : dateTo,
        }
      );
      dispatch({ type: responseClientSelfExclusion, response });
    },

  getBlockedCompetition: (clientId, limit, offSet) => async (dispatch) => {
    dispatch({ type: requestClientBlockedCompetition });

    const [error, response] = await fetchPost(
      `/blockedclient`,
      {
        clientId: clientId ? clientId : null,
        limit: limit,
        offset: offSet
      }
    );
    dispatch({ type: responseClientBlockedCompetition, response });
  },

  updateBlockedCompetitionStatus: (blockedClientCompetitionId) => async (dispatch, getState) => {
    const store = getState();
    const { userData } = store.usersApp;
    const { currentUserDetail } = store.clientInfo;
    const { blockedCompetetionList } = store.clientInfo;

    const addBlockedCompetition = (data, callback) => async (dispatch) => {
      dispatch({ type: requestClientBlockedCompetition });

      const [error, response] = await fetchPost(
        `/clients/BlockedClientCompetition`,
        data
      );
      dispatch({ type: requestClientBlockedCompetitionDone, response });
      callback && callback()
    }
    if (blockedCompetetionList && blockedCompetetionList.items) {
      const itemIndex = blockedCompetetionList.items.findIndex(item => item.blockedClientCompetitionId == blockedClientCompetitionId);
      const copyData = JSON.parse(JSON.stringify(blockedCompetetionList));
      if (itemIndex != -1) {
        const copyItem = copyData.items[itemIndex];
        copyItem.status = !copyItem.status
        copyItem.updatedBy = userData.email
        copyItem.modificationDate = moment()
        copyData.items[itemIndex] = copyItem;
        dispatch(addBlockedCompetition({
          remarks: "",
          updatedBy: userData.email,
          status: copyItem.status,
          clientId: currentUserDetail.clientId,
          blockedClientCompetitionId: blockedClientCompetitionId,
          leagueCompetitionId: copyData.items[itemIndex].leagueCompetitionId,
        }, () => {
          dispatch({ type: responseClientBlockedCompetition, response: copyData });
        }))

      }
    }
  },

  updateCurrentClientTab: (currentTab) => async (dispatch) => {
    dispatch({ type: updateClientActiveTab, payload: currentTab });
  },

  addBlockedCompetition: (data, callback) => async (dispatch) => {
    dispatch({ type: requestClientBlockedCompetition });
    const [error, response] = await fetchPost(
      `/clients/BlockedClientCompetition`,
      data
    );

    dispatch({ type: responseClientBlockedCompetition, response });
    callback()
  },

  getChatRulesById: (clientId) => async (dispatch) => {
    dispatch({ type: requestClientChatRules });
    const [, response] = await fetchGet(`/client/rules/${clientId}`);
    dispatch({ type: responseClientChatRules, response });
  },

  updateClientChatRules:
    (clientId, clientRuleId, value) => async (dispatch) => {
      dispatch({ type: requestClientChatRulesUpdate });
      const [, response] = await fetchPost(`/client/rules/${clientId}`, {
        clientRuleId: clientRuleId,
        value: value.toString(),
      });
      if (response && response.errorNo !== 0)
        toastr.error(response && response.message);
      dispatch(actionCreators.getChatRulesById(clientId));
    },
  createNewManualWithdrawal:
    (bsbNumber, accNumber, accName, amount, clientId) => async (dispatch) => {
      const [, response] = await fetchPost("/finance/manual-withdrawal", {
        bsbNumber: bsbNumber,
        accountNumber: accNumber,
        accountName: accName,
        amount: amount,
        clientId: clientId,
      });
      if (response && response.errorNo === 0) {
        toastr.success(response.message);
      } else {
        toastr.error(response && response.message);
      }
    },
  addNewResponsibleGambling:
    (clientId, data, cb = () => { }) =>
      async (dispatch) => {
        const [error, response] = await fetchPost(
          `/client/${clientId}/responsible-gambling-limit`,
          data
        );
        if (response && response.errorNo === 0) {
          toastr.success(response.message || "Limit added successfully.");
        } else {
          toastr.error(response && response.message);
        }
        if (cb()) {
          cb();
        }
      },
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestClientDetailType:
      return { ...state, gettingUserDetail: true };
    case responseClientDetailType:
      return {
        ...state,
        gettingUserDetail: false,
        currentUserDetail: action.response,
      };
    case requestUpdateClientDetailType:
      return { ...state, updatingClient: true };
    case requestDeleteClientImage:
      return { ...state, deletingImage: true };
    case responseUpdateClientDetailType:
      return {
        ...state,
        updatingClient: false,
        deletingImage: false,
        currentUserDetail: action.response
          ? action.response
          : state.currentUserDetail,
      };
    case requestAddRatingType:
      return { ...state, addingRating: true };
    case responseAddRatingType:
      return {
        ...state,
        addingRating: false,
        currentUserDetail: action.response
          ? action.response
          : state.currentUserDetail,
      };
    case requestForgotPassword:
      return { ...state, requestingForgotPassword: true };
    case responseForgotPassword:
      return { ...state, requestingForgotPassword: false };
    case requestCreateClient:
      return { ...state, creatingClient: true, createClientResponse: null };
    case responseCreateClient:
      return {
        ...state,
        creatingClient: false,
        createClientResponse: action.response,
      };
    // transaction
    case responseClientAccountDetail:
      return { ...state, clientAccountDetail: action.response };
    case requestClientTransactionDetail:
      return { ...state, gettingTransactionDetail: true };
    case responseClientTransactionDetail:
      return {
        ...state,
        gettingTransactionDetail: false,
        clientTransactionDetails: action.response,
      };
    case requestCreatingTransaction:
      return { ...state, creatingTransaction: true };
    case requestCreateClientTransaction:
      return {
        ...state,
        creatingTransaction: false,
        createSuccessMessage: action.response,
      };
    case resetError:
      return { ...state, createSuccessMessage: null };
    case requestValidateClientId:
      return { ...state, validatedResult: false };
    case responseValidateClientId:
      return {
        ...state,
        validatedResult:
          action.response && action.response.map((r) => r.isValid)[0],
      };
    case requestClientNotes:
      return { ...state, isNotesRequested: true };
    case responseClientNotes:
      return {
        ...state,
        isNotesRequested: false,
        clientNotes: action.response,
      };
    case responseCreateClientNotes:
      return {
        ...state,
        isNotesRequested: false,
        clientNotes: action.response,
      };
    case requestClientResponsibleGambling:
      return { ...state, isResponsibleGamblingRequested: true };
    case responseClientResponsibleGambling:
      return {
        ...state,
        isResponsibleGamblingRequested: false,
        clientResponsibleGambling: action.response,
      };
    case requestClientSelfExclusion:
      return { ...state, isSelfExclusionRequested: true };

    case requestClientBlockedCompetition:
      return { ...state, isBlockedCompetetionRequested: true };
    case requestClientBlockedCompetition:
      return { ...state, isBlockedCompetetionRequested: true };

    case responseClientSelfExclusion:
      return {
        ...state,
        isSelfExclusionRequested: false,
        clientSelfExclusion: action.response,
      };
    case responseClientBlockedCompetition:
      return {
        ...state,
        isBlockedCompetetionRequested: false,
        blockedCompetetionList: action.response,
      };
    case requestClientBlockedCompetitionDone: return {
      ...state,
      isBlockedCompetetionRequested: false,
    };
    //Chat Rules Setting tab
    case responseClientChatRules:
      return { ...state, chatRules: action.response };
    case updateClientActiveTab:
      return { ...state, currentActiveTab: action.payload };
    default:
      return state;
  }
};
