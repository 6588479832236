import { createSlice } from "@reduxjs/toolkit";
import { getBetListData, getPendingBets } from "../../../utility/thunks";
import { ItemLimitsInPage } from "../../../commons";
import { updateCurrencyCode } from "../../../countryConfigurations";

export const initialFilterState = {
  searchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList: "",
};

const initialState = {
  loading: false,
  betList: {
    betData: [],
    pendingBets: [],
    errorNo: 0,
    errorMessage: "",
    loading: false,
    selectedBet: null,
    lreasons: [],
    settleBetsResult: null,
  },
};

export const betListSlice = createSlice({
  name: "betlist",
  initialState,
  reducers: {
    updateRegionCode: (state, action) => {
      //state.categories.categoriesListSelectedCountry = action.payload; //does not exist here
      updateCurrencyCode(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBetListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBetListData.fulfilled, (state, action) => {
        state.loading = false;
        state.betList = action.payload;
      })
      .addCase(getBetListData.rejected, (state, action) => {
        state.loading = false;
        state.betList.errorNo = action.payload.errorNo;
        state.betList.errorMessage = action.payload.errorMessage;
      })
      .addCase(getPendingBets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingBets.fulfilled, (state, action) => {
        state.loading = false;
        state.betList = action.payload;
      })
      .addCase(getPendingBets.rejected, (state, action) => {
        state.loading = false;
        state.betList.errorNo = action.payload.errorNo;
        state.betList.errorMessage = action.payload.errorMessage;
      });
  },
});

export const { updateRegionCode } = betListSlice.actions;
export default betListSlice.reducer;
