import { createSlice } from "@reduxjs/toolkit";
import {
  getSystemSettingsConfig,
  updateSystemConfigSettings,
} from "../utility/thunks";

const initialState = {
  loading: true,
  systemConfigurations: {
    systemSettings: null,
    countryStates: null,
    updatingSystemSettings: false,
    updatingBetSettings: false,
    errorNo: 0,
    errorMessage: "",
    loading: false,
  },
  
  systemSettingsConfigurations: {
    systemSettings: null,
    countryStates: null,
    updatingSystemSettings: false,
    updatingBetSettings: false,
    errorNo: 0,
    errorMessage: "",
    loading: false,
  },
};

const updateitemsofArray = (array, values) => { 
  return array.splice(0, 0, values);
};

export const configureSettingsSlice = createSlice({
  name: "configureSettings",
  initialState,
  reducers: {
    updateConfigureSettings: (state, action) => {
      state[action.payload.ItemListName].updatingSystemSettings =
        action.payload.updatingSystemSettings;
      state[action.payload.ItemListName].updatingBetSettings =
        action.payload.updatingBetSettings;
    },
    // modifyConfigureSettings: (state, action) => {      
    //   state.countryStates = updateitemsofArray(state, "countryStates", {
    //     countryCode: "default",
    //     country: "default",
    //     states: [],
    //   });
     
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemSettingsConfig.rejected, (state, action) => {
        state.systemConfigurations.loading = false;
        state.systemConfigurations.errorNo = action.payload.errorNo;
        state.systemConfigurations.errorMessage = action.payload.errorMessage;
      })

      .addCase(getSystemSettingsConfig.pending, (state) => {
        state.systemSettingsConfigurations.loading = true;
      })
      .addCase(getSystemSettingsConfig.fulfilled, (state, action) => {
        state.systemSettingsConfigurations.loading = false;
        state.systemSettingsConfigurations = action.payload;
        state.countryStates = updateitemsofArray(action.payload.countryStates, {
          countryCode: "default",
          country: "default",
          states: [],
        });        
      })
  
      .addCase(updateSystemConfigSettings.pending, (state) => {
        state.systemConfigurations.loading = true;
      })
      .addCase(updateSystemConfigSettings.fulfilled, (state, action) => {
        state.systemConfigurations.loading = false;
        state.systemConfigurations.errorNo = action.payload.errorNo;
        state.systemConfigurations.errorMessage = action.payload.errorMessage;
      })
      .addCase(updateSystemConfigSettings.rejected, (state, action) => {
        state.systemConfigurations.loading = false;
        state.systemConfigurations.errorNo = action.payload.errorNo;
        state.systemConfigurations.errorMessage = action.payload.errorMessage;
      });
  },
});

export const { updateConfigureSettings } = configureSettingsSlice.actions;
export default configureSettingsSlice.reducer;
