import {clientsConstants} from "./constants";
import {authHeaderJson} from "../_helpers/auth-header";
import {handleResponse} from "../_helpers/handle-response";
import {toastr} from "react-redux-toastr";
import { fetchPost } from "../../../commons";

export function getClients(searchTerm, limit, offset, callback) {
  
  return (dispatch) => {
    const countryCode = localStorage.getItem("region");
    if(!countryCode) localStorage.setItem("region", "AU");
    fetchPost(`/clients`, {  
      limit: limit || 10,
      term: searchTerm || null,
      offset : offset || 0,
      multiCountryRegionCode: countryCode ? countryCode : "AU",
      }).then(([error, response]) => {
      if (response) {
        dispatch(success(response));
        callback && callback(true)
      } else {
        callback && callback(false)
      }
      }).catch(error => {
        dispatch(failure(error));
        callback && callback(false)
    });
  }


  function request() {
    return {
      type: clientsConstants.GETCLIENTS_BEGIN
    };
  }

  function success(data) {
    return {
      type: clientsConstants.GETCLIENTS_SUCCESS,
      payload: data
    };
  }

  function failure(error) {
    return {
      type: clientsConstants.GETCLIENTS_FAILURE,
      error
    };
  }
}

export function updateClient(client) {
  return dispatch => {
    dispatch(request());

    dispatch(() => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();
      const requestOptions = {
        method: "POST",
        headers: authHeaderJson(),
        body: JSON.stringify(client)
      };

      fetch(`${apiUrl}/client`, requestOptions)
        .then(handleResponse)
        .then(data => {
          dispatch(success(data));
        })
        .catch(err => {
          console.error("error:", err);
          dispatch(failure(err));
        });
    });
  };

  function request() {
    return {
      type: clientsConstants.GETCLIENTS_BEGIN
    };
  }

  function success(data) {
    toastr.success("Client details saved");
    return {
      type: clientsConstants.GETCLIENTS_SUCCESS,
      payload: data
    };
  }

  function failure(error) {
    return {
      type: clientsConstants.GETCLIENTS_FAILURE,
      error
    };
  }
}

export function getClientStatuses() {
  return dispatch => {
    const countryCode = localStorage.getItem("region");
    if(!countryCode) localStorage.setItem("region", "AU");
    dispatch(request());

    dispatch(() => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();
      const requestOptions = {
        method: "GET",
        headers: authHeaderJson()
      };

      fetch(`${apiUrl}/client-statuses?multiCountryRegionCode=${countryCode}`, requestOptions)
        .then(handleResponse)
        .then(data => {
          dispatch(success(data));
        })
        .catch(err => {
          console.error("error:", err);
          dispatch(failure(err));
        });
    });
  };

  function request() {
    return {
      type: clientsConstants.GETCLIENTSTATUSES_BEGIN
    };
  }

  function success(data) {
    return {
      type: clientsConstants.GETCLIENTSTATUSES_SUCCESS,
      payload: data
    };
  }

  function failure(error) {
    toastr.error(`Get client statuses failed - ${error.statusText}`);
    return {
      type: clientsConstants.GETCLIENTSTATUSES_FAILURE,
      error
    };
  }
}
