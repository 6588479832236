const initState = {
  venueData: {},
  venueById: {}
};

const venueReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_VENUE_LIST":
      return { ...state, venueData: payload };
    case "GET_VENUE_BY_ID":
      return { ...state, venueById: payload };
    default:
      return state;
  }
};

export default venueReducer;
