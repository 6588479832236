export const eventsConstants = {
    GETSPORTS_BEGIN: "GETSPORTS_BEGIN",
    GETSPORTS_SUCCESS: "GETSPORTS_SUCCESS",
    GETSPORTS_FAILURE: "GETSPORTS_FAILURE",

    GET_PARENT_CATEGORY_BEGIN: "GET_PARENT_CATEGORY_BEGIN",
    GET_PARENT_CATEGORY_SUCCESS: "GET_PARENT_CATEGORY_SUCCESS",
    GET_PARENT_CATEGORY_FAILURE: "GET_PARENT_CATEGORY_FAILURE",

    GET_CATEGORY_BEGIN: "GET_CATEGORY_BEGIN",
    GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
    GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
    
    GET_COMPETITION_BEGIN: "GET_COMPETITION_BEGIN",
    GET_COMPETITION_SUCCESS: "GET_COMPETITION_SUCCESS",
    GET_COMPETITION_FAILURE: "GET_COMPETITION_FAILURE",
    
    GET_SEASONS_BEGIN: "GET_SEASONS_BEGIN",
    GET_SEASONS_SUCCESS: "GET_SEASONS_SUCCESS",
    GET_SEASONS_FAILURE: "GET_SEASONS_FAILURE",
    
    GETLEAGUES_BEGIN: "GETLEAGUES_BEGIN",
    GETLEAGUES_SUCCESS: "GETLEAGUES_SUCCESS",
    GETLEAGUES_FAILURE: "GETLEAGUES_FAILURE",

    GETALLSEASONS_BEGIN: "GETALLSEASONS_BEGIN",
    GETALLSEASONS_SUCCESS: "GETALLSEASONS_SUCCESS",
    GETALLSEASONS_FAILURE: "GETALLSEASONS_FAILURE",

    GETFIXTURES_BEGIN: "GETFIXTURES_BEGIN",
    GETFIXTURES_SUCCESS: "GETFIXTURES_SUCCESS",
    GETFIXTURES_FAILURE: "GETFIXTURES_FAILURE",

    GETTEAMS_BEGIN: "GETTEAMS_BEGIN",
    GETTEAMS_SUCCESS: "GETTEAMS_SUCCESS",
    GETTEAMS_FAILURE: "GETTEAMS_FAILURE",

    GETPLAYERS_BEGIN: "GETPLAYERS_BEGIN",
    GETPLAYERS_SUCCESS: "GETPLAYERS_SUCCESS",
    GETPLAYERS_FAILURE: "GETPLAYERS_FAILURE",

    GETSEASONS_BEGIN: "GETSEASONS_BEGIN",
    GETSEASONS_SUCCESS: "GETSEASONS_SUCCESS",
    GETSEASONS_FAILURE: "GETSEASONS_FAILURE",

    GETROUNDS_BEGIN: "GETROUNDS_BEGIN",
    GETROUNDS_SUCCESS: "GETROUNDS_SUCCESS",
    GETROUNDS_FAILURE: "GETROUNDS_FAILURE",

    GETEVENTS_BEGIN: "GETEVENTS_BEGIN",
    GETEVENTS_SUCCESS: "GETEVENTS_SUCCESS",
    GETEVENTS_FAILURE: "GETEVENTS_FAILURE",

    GETEVENTCOMPETITORS_BEGIN: "GETEVENTCOMPETITORS_BEGIN",
    GETEVENTCOMPETITORS_SUCCESS: "GETEVENTCOMPETITORS_SUCCESS",
    GETEVENTCOMPETITORS_FAILURE: "GETEVENTCOMPETITORS_FAILURE",

    UPLOADSPORTS_BEGIN: "UPLOADSPORTS_BEGIN",
    UPLOADSPORTS_SUCCESS: "UPLOADSPORTS_SUCCESS",
    UPLOADSPORTS_FAILURE: "UPLOADSPORTS_FAILURE",

    GETLOCATIONS_BEGIN: "GETLOCATIONS_BEGIN",
    GETLOCATIONS_SUCCESS: "GETLOCATIONS_SUCCESS",
    GETLOCATIONS_FAILURE: "GETLOCATIONS_FAILURE"

};