import { toastr } from "react-redux-toastr";
import {
  CategoriesTabs,
  CompetitionLeagueTabs,
  fetchPost,
  fetchPut,
  generateReduxKey,
  ItemLimitsInPage,
  ItemType,
  sortByCompetitionOptions,
  sortByFixtureOptions,
  sortBySportOptions,
  sortByText,
  titleCase,
  fetchGet,
  isNotNullEmptyandUndefined,
} from "../commons";
import { stringify } from 'query-string';
import { updateCurrencyCode } from "../countryConfigurations";

export const requestSearchItems = "REQUEST_SEARCH_ITEMS";
export const responseSearchItems = "RESPONSE_SEARCH_ITEMS";

export const requestSetCategories = "REQUEST_SET_CATEGORIES";

export const requestSetSearchFilter = "REQUEST_SET_SEARCH_FILTER";

export const requestChangeTab = "REQUEST_CHANGE_TAB";
export const requestSetSelectedItemIds = "REQUEST_SET_SELECTED_ITEM_IDS";
export const requestSortItems = "REQUEST_SORT_ITEMS";
export const setLoadingItems = "SET_LOADING_ITEMS";

export const GET_VENUE_LIST = "GET_VENUE_LIST";
export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const UPDATE_FIXTURE_TEAMLIST = "UPDATE_FIXTURE_TEAMLIST";
export const GET_TEAM_LIST_EDIT = "GET_TEAM_LIST_EDIT";
export const GET_DIVISION_LIST = "GET_DIVISION_LIST";
export const GET_TIMEZONE_LIST = "GET_TIMEZONE_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_ALL_SPORTS_LIST = "GET_ALL_SPORTS_LIST";
export const GET_COMPETITION_BY_ID = "GET_COMPETITION_BY_ID";
export const GET_ALL_COMPETITION = "GET_ALL_COMPETITION";
export const GET_SELECTED_SPORTS_NAME = "GET_SELECTED_SPORTS_NAME";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_CMS_CATEGORY_LIST = "GET_CMS_CATEGORY_LIST";
export const GET_CATEGORY_COMPETITION_LIST = "GET_CATEGORY_COMPETITION_LIST";
export const GET_RESTRICTED_LOCATIONS_LIST = "GET_RESTRICTED_LOCATIONS_LIST";
export const UPDATE_RESTRICTED_LOCATION_STATUS_REQUEST = "UPDATE_RESTRICTED_LOCATION_STATUS_REQUEST";

export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const setCategoryCountry = (state) => state.usersApp.userData.defaultCountry;
//export const countryAccessList = (state) => state.usersApp.userData.ccList;
export const countryAccessList = (state) => state.usersApp.userData.countryFilters;
export const getCountryCategory = (state) => state.categories.categoriesListSelectedCountry;


export const initialFilterState = {
  searchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
};

const categoryListInitialState = {
  categoriesListSelectedCountry:"",
  updatingStatusIds: [],
  updatingHandshakeIds: [],
  selectedItemIds: [],
  items: [],
  loading: false,
  numberOfPages: 1,
  sortBy: sortBySportOptions[0].key,
  ...initialFilterState,
};

const competitionListInitialState = {
  updatingStatusIds: [],
  updatingHandshakeIds: [],
  parents: [],
  selectedItemIds: [],
  items: [],
  loading: false,
  numberOfPages: 1,
  sortBy: sortByCompetitionOptions[0].key,
  competitionCountries: [],
  selectedCountry: "",
  ...initialFilterState,
};

const seasonListInitialState = {
  updatingStatusIds: [],
  updatingHandshakeIds: [],
  parents: [],
  selectedItemIds: [],
  items: [],
  loading: false,
  numberOfPages: 1,
  sortBy: sortByCompetitionOptions[2].key,
  ...initialFilterState,
};
const seasonBetListInitialState = {
  updatingStatusIds: [],
  updatingHandshakeIds: [],
  parents: [],
  selectedItemIds: [],
  items: [],
  loading: false,
  numberOfPages: 1,
  sortBy: sortByCompetitionOptions[2].key,
  ...initialFilterState,
};
const fixtureListInitialState = {
  updatingStatusIds: [],
  updatingHandshakeIds: [],
  parents: [],
  selectedItemIds: [],
  items: [],
  loading: false,
  numberOfPages: 1,
  sortBy: sortByFixtureOptions[3].key,
  ...initialFilterState,
};

const initialState = {
  tab: CategoriesTabs.Sports,
  loadingCategories: false,
  venueList: [],
  teamList: [],
  searchedTeamList: [],
  editTeamList: [],
  countryList: [],
  restrictedLocations: [],
  categories: [],
  timeZonesList: [],
  divisionList: [],
  competitionById: {},
  [`${CategoriesTabs.Sports}-${ItemType.Sport}`]: categoryListInitialState,
  [`${CategoriesTabs.Entertainment}-${ItemType.Sport}`]:
    categoryListInitialState,
  [`${CategoriesTabs.Novelty}-${ItemType.Sport}`]: categoryListInitialState,
  [`${CategoriesTabs.Sports}-${ItemType.Competition}-${CompetitionLeagueTabs.International}`]:
    competitionListInitialState,
  [`${CategoriesTabs.Sports}-${ItemType.Competition}-${CompetitionLeagueTabs && CompetitionLeagueTabs.Domestic}`]:
    competitionListInitialState,
  [`${CategoriesTabs.Sports}-${ItemType.Competition}-${CompetitionLeagueTabs.MinorLeague}`]:
    competitionListInitialState,

  [`${CategoriesTabs.Novelty}-${ItemType.Competition}-${CompetitionLeagueTabs.International}`]:
    competitionListInitialState,
  [`${CategoriesTabs.Novelty}-${ItemType.Competition}-${CompetitionLeagueTabs && CompetitionLeagueTabs.Domestic}`]:
    competitionListInitialState,
  [`${CategoriesTabs.Novelty}-${ItemType.Competition}-${CompetitionLeagueTabs.MinorLeague}`]:
    competitionListInitialState,

  [`${CategoriesTabs.Entertainment}-${ItemType.Competition}-${CompetitionLeagueTabs.International}`]:
    competitionListInitialState,
  [`${CategoriesTabs.Entertainment}-${ItemType.Competition}-${CompetitionLeagueTabs && CompetitionLeagueTabs.Domestic}`]:
    competitionListInitialState,
  [`${CategoriesTabs.Entertainment}-${ItemType.Competition}-${CompetitionLeagueTabs.MinorLeague}`]:
    competitionListInitialState,

  [`${CategoriesTabs.Sports}-${ItemType.Season}`]: seasonListInitialState,
  [`${CategoriesTabs.Entertainment}-${ItemType.Season}`]:
    seasonListInitialState,
  [`${CategoriesTabs.Novelty}-${ItemType.Season}`]: seasonListInitialState,

  [`${CategoriesTabs.Sports}-${ItemType.Fixture}`]: fixtureListInitialState,
  [`${CategoriesTabs.Entertainment}-${ItemType.Fixture}`]:
    fixtureListInitialState,
  [`${CategoriesTabs.Novelty}-${ItemType.Fixture}`]: fixtureListInitialState,

  [`${CategoriesTabs.Sports}-${ItemType.Season}-${ItemType.SeasonBet}`]:
    seasonBetListInitialState,
  [`${CategoriesTabs.Entertainment}-${ItemType.Season}-${ItemType.SeasonBet}`]:
    seasonListInitialState,
  [`${CategoriesTabs.Novelty}-${ItemType.Season}-${ItemType.SeasonBet}`]:
    seasonListInitialState,
};

export const actionCreators = {
  
  setSelectedItemIds:
    (key, selectedItemIds = []) =>
      async (dispatch, getState) => {
        dispatch({
          type: requestSetSelectedItemIds,
          payload: { selectedItemIds, key },
        });
      },
  changeTab: (newTab) => async (dispatch, getState) => {
    dispatch({ type: requestChangeTab, payload: newTab });
  },
  setFilter: (key, filter) => async (dispatch, getState) => {  
    dispatch({ type: requestSetSearchFilter, payload: { key, filter } });
  },
  sortItems: (key, propertyName, isAsc) => async (dispatch, getState) => {
    dispatch({ type: requestSortItems, payload: { key, propertyName, isAsc } });
  },
  searchItems:
    ({ itemType, mainTab, leagueTab, itemId }) =>
      async (dispatch, getState) => { 

        const countryCategoryCode = localStorage.getItem('region');
        if(!isNotNullEmptyandUndefined)
        {dispatch(UpdateCountrySelection(getState().usersApp.userData.defaultCountry));}       
        
        dispatch({
          type: requestSearchItems,
          payload: { itemType, mainTab, leagueTab, itemId },
        });
        const key = generateReduxKey({ itemType, mainTab, leagueTab, itemId });
        const state = getState().categories[key];
        const url =
          itemType === ItemType.Sport
            ? `/category-list/${itemType}`
            : `/category-list/${itemType}/${itemId}`;
        if (itemType === "fixture") {
          const [err, response] = await fetchPost(url, {
            multiCountryRegionCode:countryCategoryCode,
            limit: state.itemLimitsInPage,
            searchTerm:
              state && state.searchTerm && state.searchTerm.length > 2
                ? state.searchTerm
                : "",
            offset: (state.currentPage - 1) * 10,
            filterUpdated: {
              from: state.filterDateFrom,
              to: state.filterDateTo,
            },
            startDate: state.statDate,
            endDate: state.endDate,
            roundName: state.roundName,
            team1: state.team1,
            team2: state.team2,
            venue: state.venue,
            handshake: state.handshake,
            division: state.division,
            inactive: state.status,
            state: state.state,
            sorting: state.sortBy,
            tab:
              itemType === ItemType.Sport
                ? titleCase(mainTab)
                : itemType === ItemType.Competition
                  ? titleCase(leagueTab)
                  : itemType === ItemType.Season
                    ? titleCase(mainTab)
                    : itemType === ItemType.Fixture
                      ? titleCase(mainTab)
                      : "",
            ...(itemType === ItemType.Competition &&
              [
                CompetitionLeagueTabs && CompetitionLeagueTabs.Domestic,
                CompetitionLeagueTabs.MinorLeague,
              ].includes(leagueTab) && {
              // regionId: state.country,
              countryCode: state.country !== "all" ? state.country : null,
            }),
          });
          dispatch({
            type: responseSearchItems,
            payload: { itemType, mainTab, leagueTab, itemId, response },
          });
        } else {
          const [err, response] = await fetchPost(url, {
            multiCountryRegionCode:countryCategoryCode,
            limit: state.itemLimitsInPage,
            searchTerm:
              state && state.searchTerm && state.searchTerm.length > 2
                ? state.searchTerm
                : "",
            offset: (state.currentPage - 1) * 10,
            // offset: (state.currentPage - 1) * state.itemLimitsInPage,
            filterUpdated: {
              from: state.filterDateFrom,
              to: state.filterDateTo,
            },
            sorting: state.sortBy,
            tab:
              itemType === ItemType.Sport
                ? titleCase(mainTab)
                : itemType === ItemType.Competition
                  ? titleCase(leagueTab)
                  : itemType === ItemType.Season
                    ? titleCase(mainTab)
                    : itemType === ItemType.Fixture
                      ? titleCase(mainTab)
                      : "",
            ...(itemType === ItemType.Competition &&
              [
                CompetitionLeagueTabs && CompetitionLeagueTabs.Domestic,
                CompetitionLeagueTabs.MinorLeague,
              ].includes(leagueTab) && {
              // regionId: state.country,
              countryCode: state.country !== "all" ? state.country : null,
            }),
          });
          dispatch({
            type: responseSearchItems,
            payload: { itemType, mainTab, leagueTab, itemId, response },
          });
        }
      },
  setCategories:
    (key, categories = []) =>
      async (dispatch, getState) => {
        dispatch({ type: requestSetCategories, payload: { key, categories } });
      },
  updateStatus:
    ({
      itemType,
      mainTab,
      leagueTab,
      statusOrHandshake,
      newStatus,
      specificIds = null,
      itemId,
      itemSeasonBetType,
    }) =>
      async (dispatch, getState) => {
        const key = itemSeasonBetType ?
          generateReduxKey({ itemType, mainTab, itemSeasonBetType, itemId }) :
          generateReduxKey({ itemType, mainTab, leagueTab, itemId: "", });
        const state = getState().categories[key];
        const ids = specificIds ? specificIds : state && state.selectedItemIds;
       
        dispatch({
          type: setLoadingItems,
          payload: { statusOrHandshake, ids, loading: true, key },
        });

        let url = itemSeasonBetType ? `/category/${itemSeasonBetType}/${statusOrHandshake}` : `/category/${itemType}/${statusOrHandshake}`;

        const cmsSelectedCountry = getState().categories.categoriesListSelectedCountry;
        const [err, response] = await fetchPut(
          url,
          {
            ids: ids,
            ...(statusOrHandshake === "status" && { active: newStatus, multiCountryRegionCode: cmsSelectedCountry }),
            ...(statusOrHandshake === "handshake" && { handshake: newStatus, multiCountryRegionCode: cmsSelectedCountry }),
          }
        );
        dispatch({
          type: setLoadingItems,
          payload: { statusOrHandshake, ids, loading: false, key },
        });
        setTimeout(
          () =>
            {
              if(itemSeasonBetType){
                dispatch(
                  getSeasonBetData({
                    itemType: itemType,
                    mainTab: mainTab,
                    // leagueTab: leagueTab,
                    itemSeasonBetType: itemSeasonBetType,
                    itemId: itemId,
                  })
                )
              }
              else dispatch(
              actionCreators.searchItems({ itemType, mainTab, leagueTab, itemId })
            )
          },
          100
        );
      },
      
   getRestrictedLocations:
    (sportId) => async (dispatch, getState) => {
      const categoriesSelectedCountry = getState().categories.categoriesListSelectedCountry;
      let params = { multiCountryRegionCode: categoriesSelectedCountry}
      let url = `/settings/categories/restricted-locations/${sportId}?${stringify(params)}`;
      fetchGet(url).then(([error, response]) => {
        if (response) {
          dispatch(success(response));
        } else {
          dispatch(failure(error));
        }
      });

      function success(data) {
        return {
          type: GET_RESTRICTED_LOCATIONS_LIST,
          payload: data,
        };
      }

      function failure(error) {
        toastr.error(`Get Restricted Locations failed - ${error && error.statusText}`);
        return {
          type: GET_RESTRICTED_LOCATIONS_LIST,
          error,
        };
      }
    },
  addRestrictedLocations:
    (values, successCallback) => async (dispatch, getState) => {
      const data = {...values, multiCountryRegionCode: getState().categories.categoriesListSelectedCountry};
      const [err, response] = await fetchPost(
        "/settings/categories/restricted-locations",
        data
      );
      if (err) toastr.error(err);
      if (response) {
        toastr.success(`${values.id ? "Updated" : "Added"} successfully`);
        if (typeof successCallback === "function") successCallback();
      }
    },
  updateRestrictedLocationStatus: (rowID) => {
    return (dispatch, getState) => {
      let items = [...getState().categories.restrictedLocations];
      let updateIndex = items.findIndex((i) => i.id === rowID);
      if (updateIndex > -1) {
        items[updateIndex] = {
          ...items[updateIndex],
          status: !items[updateIndex].status,
        };
      }
      dispatch({
        type: "UPDATE_RESTRICTED_LOCATION_STATUS_REQUEST",
        payload: items,
      });
      const values = {
        id: items[updateIndex].id,
        sportId: items[updateIndex].sportId,
        status: items[updateIndex].status,
        lastEditedBy: items[updateIndex].lastEditedBy,
        locationData: [{
          countryCode: items[updateIndex].countryCode,
          country: items[updateIndex].country,
          stateCode: [items[updateIndex].stateCode],
          state: [items[updateIndex].state]
        }]
      }
      dispatch(actionCreators.addRestrictedLocations(values))

    };
  },
};
export const getSeasonBetData = ({
  itemType,
  mainTab,
  itemSeasonBetType,
  itemId,
}) => {
  return (dispatch, getState) => {
    
    const countryCategoryCode = getState().categories.categoriesListSelectedCountry &&
    getState().categories.categoriesListSelectedCountry !== ''?
    getState().categories.categoriesListSelectedCountry
    : getState().usersApp.userData.defaultCountry;

    dispatch({
      type: requestSearchItems,
      payload: { itemType, mainTab, itemSeasonBetType, itemId },
    });
    const key = generateReduxKey({
      itemType,
      mainTab,
      itemSeasonBetType,
      itemId,
    });
    const state = getState().categories[key];
    return fetchPost(`/category-list/seasonbet/${itemId}`, {
      multiCountryRegionCode:countryCategoryCode,
      limit: state.itemLimitsInPage,
      searchTerm:
        state && state.searchTerm && state.searchTerm.length > 2
          ? state.searchTerm
          : "",
      offset: (state.currentPage - 1) * 10,
      filterUpdated: {
        from: state.filterDateFrom,
        to: state.filterDateTo,
      },
      sorting: state.sortBy,
      tab: null,
    }).then(([error, response]) => {
      if (response) {
        return dispatch({
          type: responseSearchItems,
          payload: { itemType, mainTab, itemSeasonBetType, itemId, response },
        });
      } else {
        dispatch(failure(error));
      }
      return [error, response];
    });
  };
  function failure(error) {
    toastr.error(`Get Venue failed - ${error && error.statusText}`);
  }
};
export const updateCompetitionDetails = (data) => {
  const { itemType, statusOrHandshake, newStatus, ids } = data;
  
  return (dispatch,getState) => {
    const cmsSelectedCountry = getState().categories.categoriesListSelectedCountry; 
    return fetchPut(`/category/${itemType}/${statusOrHandshake}`, {
      ids,      
      ...(statusOrHandshake === "status" && { active: newStatus, multiCountryRegionCode: cmsSelectedCountry }),
      ...(statusOrHandshake === "handshake" && { handshake: newStatus, multiCountryRegionCode: cmsSelectedCountry }),
    }).then(([error, response]) => {
      if (response) {
        return;
      } else {
        failure(error);
      }
    });

    function failure(error) {
      toastr.error(`Update Status failed - ${error && error.statusText}`);
      return;
    }
  };
};

export const getCategoryNames = (data) => {
  return (dispatch) => {
    fetchPost(`/category-list/sport`, data).then(([error, response]) => {
      if (response) {
        let data = response.items.reduce((i, j) => {
          i.push({
            key: j.id,
            value: j.id,
            text: j.name,
            category: j.category,
            item: j,
          });
          return i;
        }, []);
        dispatch(success(data));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_CMS_CATEGORY_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Venues failed - ${error && error.statusText}`);
    return {
      type: GET_CMS_CATEGORY_LIST,
      error,
    };
  }
};

export const getCategoryCompetition = (id, data) => {
  return (dispatch) => {
    fetchPost(`/category-list/competition/${id}`, data).then(
      ([error, response]) => {
        if (response) {
          let data = response.items.reduce((i, j) => {
            i.push({
              key: j.id,
              value: j.id,
              text: j.name,
              category: j.category,
              item: j,
            });
            return i;
          }, []);
          dispatch(success(data));
        } else {
          dispatch(failure(error));
        }
      }
    );
  };

  function success(data) {
    return {
      type: GET_CATEGORY_COMPETITION_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Venues failed - ${error && error.statusText}`);
    return {
      type: GET_CATEGORY_COMPETITION_LIST,
      error,
    };
  }
};

export const getVenuesList = () => {
  return (dispatch) => {
    return fetchPost(`/master/venueList`, {
      limit: null,
      countrycode: null,
      searchTerm: "",
      sorting: "-Country",
      filterUpdated: {
        from: null,
        to: null,
      },
    }).then(([error, response]) => {
      if (response) {
        let newData = [];
        let data =
          response &&
          response.venuesList &&
          response.venuesList.map((i, j) => {
            newData.push({
              key: i.venueId,
              value: i.venueName,
              text: i.venueName,
            });
            return i;
          }, []);
        dispatch(success(newData));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_VENUE_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Venues failed - ${error && error.statusText}`);
    return {
      type: GET_VENUE_LIST,
      error,
    };
  }
};

export const getCategoryList = (itemType, data) => {
  return (dispatch) => {
    return fetchPost(`/category-list/${itemType}`, data).then(
      ([error, response]) => {
        if (response) {
          let data = response.items.map((i) => {
            return {
              value: i.id,
              label: i.name,
              inactive: i.inactive,
              handshake: i.handshake,
            };
          });
          dispatch(success(data));
          dispatch(setSelectedSports({ value: "all", label: "All" }));
        } else {
          dispatch(failure(error));
        }
      }
    );
  };

  function success(data) {
    return {
      type: GET_ALL_SPORTS_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Category Lists - ${error && error.statusText}`);
    return {
      type: GET_ALL_SPORTS_LIST,
      error,
    };
  }
};

export const setSelectedSports = (data) => {
  return {
    type: GET_SELECTED_SPORTS_NAME,
    payload: data,
  };
};

export const getAllCompetition = (data) => {
  const {
    leagueTab,
    itemType,
    limit,
    currentPage,
    sortBy,
    searchTerm,
    filterUpdated,
    countryCode,
  } = data;

  return (dispatch, getState) => {
    const { selectedSports } = getState().categories;
    let url =
      selectedSports && selectedSports.value !== "all"
        ? `/category-list/competition/${selectedSports.value}`
        : `/category-list/competition`;
    return fetchPost(url, {
      limit: limit,
      searchTerm: searchTerm && searchTerm.length > 2 ? searchTerm : "",
      offset: (currentPage - 1) * limit,
      filterUpdated,
      sorting: sortBy,
      countryCode: countryCode,
      tab: leagueTab,
    }).then(([error, response]) => {
      if (response) {
        let data = response;
        dispatch(success(data));
        dispatch({
          type: GET_COMPETITION_BY_ID,
          payload: {},
        });
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_ALL_COMPETITION,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Category Lists - ${error && error.statusText}`);
    return {
      type: GET_ALL_COMPETITION,
      error,
    };
  }
};

export const getCountryList = () => {
  return (dispatch) => {
    fetchGet(`/master/countryList`).then(([error, response]) => {
      if (response) {
        let data = response.countriesList.map((i, j) => {
          return { value: i.countryCode, label: i.countryName };
        }, []);
        dispatch(success(data));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_COUNTRY_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Country failed - ${error && error.statusText}`);
    return {
      type: GET_COUNTRY_LIST,
      error,
    };
  }
};

export const getStateList = (data) => {
  return (dispatch) => {
    return fetchGet(`/master/stateList?countryCode=${data.countryCode}`).then(
      ([error, response]) => {
        if (response) {
          let data = response.statesList.map((i, j) => {
            return { value: i.stateCode, label: i.stateName };
          }, []);
          dispatch(success(data));
        } else {
          dispatch(failure(error));
        }
      }
    );
  };

  function success(data) {
    return {
      type: GET_STATE_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get State failed - ${error && error.statusText}`);
    return {
      type: GET_STATE_LIST,
      error,
    };
  }
};

export const getStateListForFixture = (data) => {
  return (dispatch) => {
    return fetchGet(`/master/stateList?countryCode=${data.countryCode}`).then(
      ([error, response]) => {
        if (response) {
          let data = response.statesList.map((i, j) => {
            return { key: i.stateCode, text: i.stateName, value: i.stateName };
          }, []);
          dispatch(success(data));
        } else {
          dispatch(failure(error));
        }
      }
    );
  };

  function success(data) {
    return {
      type: GET_STATE_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get State failed - ${error && error.statusText}`);
    return {
      type: GET_STATE_LIST,
      error,
    };
  }
};

export const getCompetitionById = (data) => {
  const { competitionId, leagueName } = data;
  return (dispatch, getState) => {
    const { selectedSports } = getState().categories;
    let url =
      selectedSports && selectedSports.value !== "all"
        ? `/category-list/competition/${selectedSports.value}`
        : `/category-list/competition`;
    return fetchGet(`${url}?competitionId=${competitionId}&tab=${leagueName}&limit=0`).then(
      ([error, response]) => {
        if (response) {
          let data = response.items.find((item) => item.id === competitionId);
          dispatch(success(data));
          return data;
        } else {
          dispatch(failure(error));
          return error;
        }
      }
    );
  };

  function success(data) {
    return {
      type: GET_COMPETITION_BY_ID,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Competition by id failed - ${error && error.statusText}`);
    return {
      type: GET_COMPETITION_BY_ID,
      error,
    };
  }
};

export const getTeamsList = (data) => {
  const { competitionId = "", region = 1, divisionId = "" } = data;
  return (dispatch, getState) => {
    return fetchPost(`/master/teamList`, {
      divisionId,
      competitionId,
      limit: 500,
      offset: 0,
      searchTerm: "",
      sorting: "-isMappedWithCompetition",
      region: region,
      filterUpdated: {
        from: null,
        to: null,
      },
    }).then(([error, response]) => {
      if (response) {
        let newTeamData = [];
        let data = [];
        if (divisionId === "") {
          data =
            response &&
            response.teamsList &&
            response.teamsList
              .filter((i) => i.isMappedWithCompetition === true)
              .map((i, j) => {
                newTeamData.push({
                  key: i.teamId,
                  value: i.teamName,
                  text: i.teamName,
                });
                return i;
              }, []);
        } else {
          data =
            response &&
            response.teamsList &&
            response.teamsList
              .filter((i) => i.isMappedWithDivision === true)
              .map((i, j) => {
                newTeamData.push({
                  key: i.teamId,
                  value: i.teamName,
                  text: i.teamName,
                });
                return i;
              }, []);
        }
        dispatch(success(newTeamData));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_TEAM_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Teams failed - ${error && error.statusText}`);
    return {
      type: GET_TEAM_LIST,
      error,
    };
  }
};


export const getEditTeamsList = (data) => {
    const { competitionId = "" , region = 1, divisionId = "" } = data;
    return (dispatch, getState) => {
      return fetchPost(`/master/teamList`, {
        divisionId,
        competitionId,
        limit: 500,
        offset: 0,
        searchTerm: "",
        sorting: "-isMappedWithCompetition",
        region: region,
        filterUpdated: {
          from: null,
          to: null,
        },
      }).then(([error, response]) => {
        if (response) {
          let newTeamData = [];
          let data = [];
          if (divisionId === "") {
            data =
              response &&
              response.teamsList &&
              response.teamsList
                .filter(
                  (i) =>
                    i.isMappedWithCompetition === true && i.inactive === false
                )
                .map((i, j) => {
                  newTeamData.push({
                    key: i.teamId,
                    value: i.teamName,
                    text: i.teamName,
                  });
                  return i;
                }, []);
          } else {
            data =
              response &&
              response.teamsList &&
              response.teamsList
                .filter(
                  (i) => i.isMappedWithDivision === true && i.inactive === false
                )
                .map((i, j) => {
                  newTeamData.push({
                    key: i.teamId,
                    value: i.teamName,
                    text: i.teamName,
                  });
                  return i;
                }, []);
          }
          dispatch(success(newTeamData));
        } else {
          dispatch(failure(error));
        }
      });
    };
  
    function success(data) {
      return {
        type: GET_TEAM_LIST_EDIT,
        payload: data,
      };
    }
  
    function failure(error) {
      toastr.error(`Get Teams failed - ${error && error.statusText}`);
      return {
        type: GET_TEAM_LIST_EDIT,
        error,
      };
    }
  };


export const getTeamsListFixture = (data) => {
  const { competitionId = "" , region = 1, divisionId = "" } = data;
  return (dispatch, getState) => {
    return fetchPost(`/master/teamList`, {
      divisionId,
      competitionId,      
      //limit:  data.limit && data.limit > 0?data.limit: 500,
      limit:  data.limit ,
      offset: 0,
      searchTerm: "",
      //sorting:  data.sorting && data.sorting !=="" ? data.sorting: "-isMappedWithCompetition",
      sorting:  data.sorting,
      region: region,
      filterUpdated: {
        from: null,
        to: null,
      },
    }).then(([error, response]) => {
      if (response) {
        let newTeamData = [];
        let data = [];
        if (divisionId === "") {
          data =
            response &&
            response.teamsList &&
            response.teamsList
              .filter(
                (i) =>
                  i.isMappedWithCompetition === true && i.inactive === false
              )
              .map((i, j) => {
                newTeamData.push({
                  key: i.teamId,
                  value: i.teamName,
                  text: i.teamName,
                });
                return i;
              }, []);
        } else {
          data =
            response &&
            response.teamsList &&
            response.teamsList
              .filter(
                (i) => i.isMappedWithDivision === true && i.inactive === false
              )
              .map((i, j) => {
                newTeamData.push({
                  key: i.teamId,
                  value: i.teamName,
                  text: i.teamName,
                });
                return i;
              }, []);
        }
        dispatch(success(newTeamData));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_TEAM_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Teams failed - ${error && error.statusText}`);
    return {
      type: GET_TEAM_LIST,
      error,
    };
  }
};

export const getDivisionList = (competitionId = "") => {
  return (dispatch) => {
    fetchPost(`/master/divisionList`, {
      competitionId: competitionId,
      limit: 0,
      offset: 0,
      searchTerm: "",
      sorting: "-Country",
      filterUpdated: {
        from: null,
        to: null,
      },
    }).then(([error, response]) => {
      if (response) {
        let newDivisionData = [];
        let data =
          response &&
          response.divisionsList &&
          response.divisionsList
            .filter((i) => i.isMappedWithCompetition === true)
            .map((i, j) => {
              newDivisionData.push({
                key: i.divisionId,
                value: i.divisionName,
                text: i.divisionName,
              });
              return i;
            }, []);
        dispatch(success(newDivisionData));
        // dispatch(success(response));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_DIVISION_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Division failed - ${error && error.statusText}`);
    return {
      type: GET_DIVISION_LIST,
      error,
    };
  }
};

export const getDivisionListFixture = (competitionId = "") => {
  return (dispatch) => {
    fetchPost(`/master/divisionList`, {
      competitionId: competitionId,
      limit: 0,
      offset: 0,
      searchTerm: "",
      sorting: "-Country",
      filterUpdated: {
        from: null,
        to: null,
      },
    }).then(([error, response]) => {
      if (response) {
        let newDivisionData = [];
        let data =
          response &&
          response.divisionsList &&
          response.divisionsList
            .filter(
              (i) => i.isMappedWithCompetition === true && i.inactive === false
            )
            .map((i, j) => {
              newDivisionData.push({
                key: i.divisionId,
                value: i.divisionName,
                text: i.divisionName,
              });
              return i;
            }, []);
        dispatch(success(newDivisionData));
        // dispatch(success(response));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_DIVISION_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get Division failed - ${error && error.statusText}`);
    return {
      type: GET_DIVISION_LIST,
      error,
    };
  }
};

export const getTimeZoneList = (type) => {
  return (dispatch) => {
    fetchGet(`/master/timeZoneList`).then(([error, response]) => {
      if (response) {
        let newTimeZoneData = [];
        let data =
          response &&
          response.timeZonesList &&
          response.timeZonesList.map((i, index) => {
            if (type === "season") {
              newTimeZoneData.push({
                key: `${i.timeZoneValue}+${index}`,
                value: i.timeZoneValue,
                label: i.timeZoneName,
              });
            } else {
              newTimeZoneData.push({
                key: `${i.timeZoneValue}+${index}`,
                keyText: i.timeZoneValue,
                value: i.timeZoneName,
                text: i.timeZoneName,
              });
            }
            return i;
          }, []);
        dispatch(success(newTimeZoneData));
      } else {
        dispatch(failure(error));
      }
    });
  };

  function success(data) {
    return {
      type: GET_TIMEZONE_LIST,
      payload: data,
    };
  }

  function failure(error) {
    toastr.error(`Get TimeZone failed - ${error && error.statusText}`);
    return {
      type: GET_TIMEZONE_LIST,
      error,
    };
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case GET_RESTRICTED_LOCATIONS_LIST:
      return {
        ...state,
        restrictedLocations: action.payload,
      };

    case UPDATE_RESTRICTED_LOCATION_STATUS_REQUEST:
      return {
        ...state,
        restrictedLocations: action.payload,
      };

    case GET_STATE_LIST:
      return {
        ...state,
        stateList: action.payload,
      };

    case GET_ALL_SPORTS_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };

    case GET_SELECTED_SPORTS_NAME:
      return {
        ...state,
        selectedSports: action.payload,
      };

    case GET_ALL_COMPETITION:
      return {
        ...state,
        competitionData: action.payload,
      };

    case GET_COMPETITION_BY_ID:
      return {
        ...state,
        competitionById: action.payload,
      };

    case GET_TEAM_LIST:
      return {
        ...state,
        teamList: action.payload,
      };

    case GET_TEAM_LIST_EDIT:
      return {
        ...state,
        editTeamList: action.payload,
      };

    case GET_DIVISION_LIST:
      return {
        ...state,
        divisionList: action.payload,
      };

    case GET_TIMEZONE_LIST:
      return {
        ...state,
        timeZonesList: action.payload,
      };

    case GET_VENUE_LIST:
      return {
        ...state,
        venueList: action.payload,
      };

    case SET_SELECTED_COUNTRY:
      updateCurrencyCode(action.payload);
      localStorage.setItem("region", action.payload);
      return {
        ...state,
        categoriesListSelectedCountry: action.payload,
      };

    case UPDATE_FIXTURE_TEAMLIST:
      
      return {
        ...state,
        [action.payload.ItemListName]: action.payload.replaceTeamList,
      };
   

    case setLoadingItems:
      const { statusOrHandshake, loading, ids = [] } = action.payload;
      const handShakeOrStatusKey =
        statusOrHandshake === "status"
          ? "updatingStatusIds"
          : statusOrHandshake === "handshake"
          ? "updatingHandshakeIds"
          : "";
      const existingLoadingIds =
        (state[action.payload.key] &&
          state[action.payload.key][handShakeOrStatusKey]) ||
        [];
      const newLoadingIds = loading
        ? [...existingLoadingIds, ...ids]
        : existingLoadingIds.filter((id) => !ids.includes(id));
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [handShakeOrStatusKey]: newLoadingIds,
        },
      };
    case requestSortItems:
      const propertyName = action.payload.propertyName;
      const isAsc = action.payload.isAsc;
      const sortedItems = sortByText(
        state[action.payload.key].items,
        propertyName,
        isAsc
      );
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          items: sortedItems,
        },
      };
    case requestSearchItems:
      const key = generateReduxKey({ ...action.payload });

      return {
        ...state,
        [key]: {
          ...state[key],
          loading: true,
        },
      };

    case responseSearchItems:
      const key2 = generateReduxKey({ ...action.payload });
      return {
        ...state,
        [key2]: {
          ...state[key2],
          loading: false,
          items: action.payload.response ? action.payload.response.items : [],
          numberOfPages: action.payload.response
            ? action.payload.response.numberOfPages
            : 1,
          currentPage: action.payload.response
            ? action.payload.response.currentPage
            : 1,
          parents: action.payload.response
            ? action.payload.response.parents
            : [],
          competitionCountries:
            action.payload.response &&
            action.payload.response.competitionCountries
              ? action.payload.response.competitionCountries
              : [],
          competitionTabs:
            action.payload.response && action.payload.response.competitionTabs
              ? action.payload.response.competitionTabs
              : [],
          selectedCountry:
            action.payload.response &&
            action.payload.response.competitionCountries &&
            action.payload.response.competitionCountries.find(
              (i) => i.isSelected
            )
              ? action.payload.response.competitionCountries.find(
                  (i) => i.isSelected
                ).key
              : "",
          sortingFields:
            (action.payload.response &&
              action.payload.response.sortingFields) ||
            [],
        },
      };
    case requestSetSelectedItemIds:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          selectedItemIds: action.payload.selectedItemIds,
        },
      };
    case requestChangeTab:
      return { ...state, tab: action.payload };
    case requestSetSearchFilter:
      const { filter } = action.payload;
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          searchTerm: filter.searchTerm,
          filterDateFrom: filter.filterDateFrom,
          filterDateTo: filter.filterDateTo,
          itemLimitsInPage: filter.itemLimitsInPage,
          sortBy: filter.sortBy,
          currentPage: filter.currentPage,
          country: filter.country,
          roundName: filter.roundName,
          venue: filter.venue,
          team1: filter.team1,
          team2: filter.team2,
          division: filter.division,
          status: filter.status,
          handshake: filter.handshake,
          statDate: filter.startDate,
          endDate: filter.endDate,
          state: filter.state,
        },
      };
    case requestSetCategories:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: false,
          items: action.payload.categories ? action.payload.categories : [],
        },
      };
    default:
      return state;
  }
};

export const addMultipleFixture = (data, cb = () => { }) => {
     
  return (dispatch, getState) => {
    fetchPost(`/category/Fixtures`, data).then(([error, response]) => {
      if (response) {
        success(data, cb);
      } else {
        failure(error);
      }
    });
  };

  function success(data, cb) {
    toastr.success(`Added Fixture Successfully.`);
    if (cb) {
      cb();
    }
  }

  function failure(error) {
    toastr.error(`Add Fixture Failed`);
  }
};

export const editFixture = (data, cb = () => { }) => {
  return (dispatch) => {
    fetchPost(
      `/category/Fixture/${data && data.item && data.item.id}`,
      data
    ).then(([error, response]) => {
      if (response) {
        success(data, cb);
      } else {
        failure(error);
      }
    });
  };
  function success(data, cb) {
    toastr.success(`Edited Fixture successfully.`);
    if (cb) {
      cb();
    }
  }
  function failure(error) {
    toastr.error(`Edit Fixture failed`);
  }
};

export const  UpdateCountrySelection= (data) => {
  return {
    type: SET_SELECTED_COUNTRY,
    payload: data,
  };
}

export const  UpdateTeamList= (data) => {  
  return {
    type: UPDATE_FIXTURE_TEAMLIST,
    payload: data,
  };
}

export const insertItems = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]