export const clientsConstants = {
    GETCLIENTS_BEGIN: "GETCLIENTS_BEGIN",
    GETCLIENTS_SUCCESS: "GETCLIENTS_SUCCESS",
    GETCLIENTS_FAILURE: "GETCLIENTS_FAILURE",
    UPDATECLIENTS_BEGIN: "UPDATECLIENTS_BEGIN",
    UPDATECLIENTS_SUCCESS: "UPDATECLIENTS_SUCCESS",
    UPDATECLIENTS_FAILURE: "UPDATECLIENTS_FAILURE",
    
    GETCLIENTSTATUSES_BEGIN : "GETCLIENTSTATUSES_BEGIN",
    GETCLIENTSTATUSES_SUCCESS : "GETCLIENTSTATUSES_SUCCESS",
    GETCLIENTSTATUSES_FAILURE: "GETCLIENTSTATUSES_FAILURE",
    
    ADD_BLOCKED_COMPETITION_BEGIN: 'ADD_BLOCKED_COMPETITION_BEGIN',
    ADD_BLOCKED_COMPETITION_SUCCESS: 'ADD_BLOCKED_COMPETITION_SUCCESS',
    ADD_BLOCKED_COMPETITION_FAIL : 'ADD_BLOCKED_COMPETITION_FAIL'
};