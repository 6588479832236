const initialState = {
  customizedBetReasons: [],
};
export const getCustomizedBetReasonsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CUSTOMIZED_BET_REASONS":
      return { ...state, customizedBetReasons: action.payload.betReasons };
    default:
      return state;
  }
};
