import { chatsDb } from "../init-fcm";
import { envName, fetchGet, fetchPost, throttle } from "../commons";
import { toastr } from "react-redux-toastr";

export const requestCommonChatMessage = "REQUEST_COMMON_CHAT_MESSAGE";
export const responseCommonChatMessage = "RESPONSE_COMMON_CHAT_MESSAGE";
export const requestDetail = "REQUEST_DETAIL";
export const responseDetail = "RESPONSE_DETAIL";

const dispatchChats = (dispatch, data) => {
  dispatch({ type: responseCommonChatMessage, response: data });
};
const throttleVersionOfDispatchChats = throttle(dispatchChats, 500);

const initialState = {
  loadingCommonChatMessages: false,
  commonChatMessages: [],
  loadingDetail: false,
  detail: null,
};

export const actionCreators = {
  getDetail: (type, chatId) => async (dispatch, getState) => {
    dispatch({ type: requestDetail });
    let chatIdToProcess = chatId;
    if (type === "sports") {
      chatIdToProcess = `${
        envName === "local" ? "dev" : envName
      }_sport_${chatId}`;
    }
    const [err, response] = await fetchGet(`/chat/${chatIdToProcess}`);
    dispatch({ type: responseDetail, response });
  },
  getBetsChatMessage: (chatId) => async (dispatch, getState) => {
    dispatch({ type: requestCommonChatMessage });
    chatsDb
      .collection(`BetChat/${chatId}/Messages`)
      .orderBy("MessageTime", "desc")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        throttleVersionOfDispatchChats(dispatch, data);
      });
  },
  getSportChatMessage: (sportId) => async (dispatch, getState) => {
    dispatch({ type: requestCommonChatMessage });
    const chatId = `${envName === "local" ? "dev" : envName}_sport_${sportId}`;
    chatsDb
      .collection(`SportChat/${chatId}/Messages`)
      .orderBy("MessageTime", "desc")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        throttleVersionOfDispatchChats(dispatch, data);
      });
  },
  actionComment:
    (
      chatId,
      messageId,
      newStatus,
      privateNote,
      sendPushNotification,
      overrideNotificationText = null
    ) =>
    async (dispatch, getState) => {
      const [err, response] = await fetchPost("/chat/moderate", {
        chatId,
        messageId,
        privateNote,
        newStatus,
        sendPushNotification,
        overrideNotificationText,
      });
      if (response && response.errorNo === 0) {
        toastr.success(`Action ${newStatus} is successfully executed`);
      } else if (response && response.errorNo > 0) {
        toastr.error(
          response.errorNo === 5100
            ? "Creator of this message is not the bet creator"
            : response.message
        );
      }
    },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestDetail:
      return { ...state, loadingDetail: true };
    case responseDetail:
      return { ...state, loadingDetail: false, detail: action.response };

    case requestCommonChatMessage:
      return { ...state, loadingCommonChatMessages: true };
    case responseCommonChatMessage:
      return {
        ...state,
        loadingCommonChatMessages: false,
        commonChatMessages: action.response,
      };
    default:
      return state;
  }
};
