const initState = {
    teamData: {},
    teamById : {},
    fixuresTeamData : {},
};

const teamReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case "GET_TEAM_LIST":
            return { ...state, teamData : payload };
        case "GET_TEAM_LIST_FIXURES":
            return { ...state, fixuresTeamData : payload };
        case "GET_TEAM_BY_ID" :
            return {...state, teamById: payload }
        default:
            return state;
    }
};

export default teamReducer;
