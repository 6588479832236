import { createSlice } from "@reduxjs/toolkit";
import {
  fetchfinanceAdjustment,
  getWithdrawals,
  processApproveRejectWithdrawals,
  reverseWithdrawals,
} from "../../../../utility/thunks";
import { ItemLimitsInPage } from "../../../../commons";

export const initialFilterState = {
  searchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList: "",
};

const initialState = {
  loading: true,
  withDrawalList: {
    withDrawals: [],
    errorNo: 0,
    errorMessage: "",
    loading: true,
  },
  financeAdjustments: {
    adjustmentList: [],
    errorNo: 0,
    errorMessage: "",
    loading: true,
  },
  ApproveWithdrawal: {
    withDrawals: [],
    errorNo: 0,
    errorMessage: "",
    loading: true,
  },
  withdrawalReversals: {
    withDrawals: [],
    errorNo: 0,
    errorMessage: "",
    loading: true,
  },
};

export const withdrawalSlice = createSlice({
  name: "withdrawals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawals.fulfilled, (state, action) => {
        state.loading = false;
        state.withDrawalList = action.payload;        
      })
      .addCase(getWithdrawals.rejected, (state, action) => {
        state.loading = false;
        state.withDrawalList.errorNo = action.payload.errorNo;
        state.withDrawalList.errorMessage = action.payload.errorMessage;
      })
      .addCase(fetchfinanceAdjustment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchfinanceAdjustment.fulfilled, (state, action) => {
        state.loading = false;
        state.financeAdjustments = action.payload;        
      })
      .addCase(fetchfinanceAdjustment.rejected, (state, action) => {
        state.loading = false;
        state.financeAdjustments.errorNo = action.payload.errorNo;
        state.financeAdjustments.errorMessage = action.payload.errorMessage;
      })
      .addCase(processApproveRejectWithdrawals.pending, (state) => {
        state.loading = true;
      })
      .addCase(processApproveRejectWithdrawals.fulfilled, (state, action) => {
        state.loading = false;
        state.ApproveWithdrawal= action.payload;    
      })
      .addCase(processApproveRejectWithdrawals.rejected, (state, action) => {
        console.log(action)
        state.loading = false;
        state.ApproveWithdrawal.errorNo = action.payload.errorNo;
        state.ApproveWithdrawal.errorMessage = action.payload.errorMessage;
      })
      .addCase(reverseWithdrawals.pending, (state) => {
        state.loading = true;
      })
      .addCase(reverseWithdrawals.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalReversals = action.payload;
      })
      .addCase(reverseWithdrawals.rejected, (state, action) => {
        state.loading = false;
        state.withdrawalReversals.errorNo = action.payload.errorNo;
        state.withdrawalReversals.errorMessage = action.payload.errorMessage;
      });
  },
});

export default withdrawalSlice.reducer;
