
let initialState = {
  toggleTodo: true,
};
export const toggleSidebar = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SIDE_MENU":
      return { ...state, toggleTodo: action.payload };
    default: return state
  }
};


export const menuCollapsed = (data) => {
  return {
    type: "TOGGLE_SIDE_MENU",
    payload: data,
  };
};