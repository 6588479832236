import { toastr } from "react-redux-toastr";
// import history from "../../../history";

export function handleResponse(response, hideAuthError) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      console.error("handleResponse::error::", response, data);
      const error = {
        status: response.status,
        statusText: response.statusText,
        dataErrors: data ? data.errors : [],
        dataErrorsTitle: data ? data.title : "",
      };
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // window.location.reload(true);
        window.location.reload();
      }

      if (response.status === 403) {
        if (!hideAuthError) {
          toastr.error(data.message);
        }
      }
      if (response.status === 400) {
        toastr.error(data.message);
      }

      return Promise.reject(error);
    }
    return data;
  });
}

export function handleLoginResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //window.location.reload(true);
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("permission");
}

export function handleError(error) {
  toastr.error(
    `Error calling api ${error.message || ""} ${error.statusText || error}`
  );
}
