const initState = {
    clientsData: { clients: [] }
};

const clientsReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case "GETCLIENTS_SUCCESS":
            return { ...state, clientsData: payload };
        default:
            return state;
    }
};

export default clientsReducer;