import {fetchPost} from "../commons";
import {toastr} from "react-redux-toastr";

export const requestAdminQueryModeration = "REQUEST_ADMIN_QUERY_MODERATION";
export const responseAdminQueryModeration = "RESPONSE_ADMIN_QUERY_MODERATION";
export const requestSingleAdminQueryModeration = "REQUEST_SINGLE_ADMIN_QUERY_MODERATION";
export const requestAdminModerationChat = "REQUEST_ADMIN_MODERATION_CHAT";
export const responseAdminQueryModerationFlagged = "RESPONSE_ADMIN_QUERY_MODERATION_FLAGGED"
export const requestAdminQueryModerationFlagged = "REQUEST_ADMIN_QUERY_MODERATION_FLAGGED"
const initialState = {
  requestedModeration: false,
  requestedModerationFlagged:false,
  moderationRequestList: null,
  moderationRequestListFlagged: null
}

export const actionCreators = {
  getAdminModeration: (statuses, dateFrom, dateTo) => async (dispatch) => {
    dispatch({type:requestAdminQueryModeration});
    const [, response] = await fetchPost('/moderation-request/query', {statuses, dateFrom, dateTo});
    dispatch({type: responseAdminQueryModeration, response});
  },
  getAdminModerationFlaggedProfile: (statuses, dateFrom, dateTo) => async (dispatch) => {
    dispatch({type:requestAdminQueryModerationFlagged});
    const [, response] = await fetchPost('/moderation-request/query/profile', {statuses, dateFrom, dateTo});
    dispatch({type: responseAdminQueryModerationFlagged, response});
  },

  // getSingleModeration: (moderationRequestId) => async (dispatch) => {
  //   const [, response] = await fetchGet(`/moderation-request/${moderationRequestId}`);
  //   dispatch({type: requestAdminQueryModeration, response: [response]});
  // },

  postAdminActionModeration: (moderationRequestId, newStatus, privateNote, sendPushNotification, overrideNotificationText, callBack) => async (dispatch) => {
    const [, response] = await fetchPost('/moderation-request/action', {
      moderationRequestId,
      newStatus,
      privateNote,
      sendPushNotification,
      overrideNotificationText
    });
    if (response.errorNo === 0) {
      toastr.success(`Action ${newStatus} is successfully executed`);
      dispatch(callBack);
    } else {
      toastr.error(`Action ${newStatus} failed to execute`);
    }
  },

  postAdminModerationChat: (chatId, messageId, newStatus, privateNote, sendPushNotification, overrideNotificationText) => async (dispatch) => {
    const [, response] = await fetchPost('/chat/moderate', {
      chatId,
      messageId,
      newStatus,
      privateNote,
      sendPushNotification,
      overrideNotificationText
    });
    dispatch({type: requestAdminModerationChat, response});
  },
}

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestAdminQueryModeration:
      return {...state, requestedModeration: true};
    case requestAdminQueryModerationFlagged:
      return {...state, requestedModerationFlagged: true};
    case responseAdminQueryModeration:
      return {...state, requestedModeration: false, moderationRequestList: action.response};
    case responseAdminQueryModerationFlagged:
      return {...state, requestedModerationFlagged: false, moderationRequestListFlagged: action.response};
    default:
      return state;
  }

}

